import AndamentoLista from '@/pages/movimentacoes/andamento/AndamentoLista'
import AndamentoProjeto from '@/pages/movimentacoes/andamento/AndamentoProjeto'
import permissoes from "@/pages/sistema/usuarios/permissoes";

export default [
  {
    path: '/movimentacoes/andamento', 
    name: 'AndamentoLista', 
    component: AndamentoLista,
    meta: {layout: 'principal', permissao: permissoes.andamento.ACCESAR.id}
  },

  {
    path: '/movimentacoes/andamento/:id',
    name: 'AndamentoProjeto', 
    component: AndamentoProjeto,
    meta: {layout: 'principal', permissao: permissoes.andamento.ACCESAR.id}
  },

];
