import * as icons from "../../../app/icons";

import permissoes from "./permissoes";

export default [
  {
    id: "todas",
    name: "Acesso Total",
    icon: "mdi-check-all",
    children: [
      {
        id: "cadastros",
        name: "Cadastros",
        icon: icons.CADASTROS,
        visible: true,
        children: [
          { 
            id: "clientes", 
            name: "Clientes", 
            icon: icons.CLIENTES, 
            to: { name: 'ClientesLista' }, 
            permissao: permissoes.clientes.ACCESAR.id,
            disabled: false, 
            children: [
              permissoes.clientes.ACCESAR,
              permissoes.clientes.ADICIONAR,
              permissoes.clientes.EDITAR,
              permissoes.clientes.EXCLUIR,
              permissoes.clientes.IMPRIMIR,
            ],
          },
          { 
            id: "projetos", 
            name: "Projetos", 
            icon: icons.PROJETOS, 
            to: { name: 'ProjetosLista' }, 
            permissao: "projetos_acessar",
            disabled: false, 
            children: [
              { id: "projetos_acessar", name: "Acessar", icon: icons.ACESSAR, },
              { id: "projetos_adicionar", name: "Adicionar", icon: icons.ADICIONAR },
              { id: "projetos_editar", name: "Editar", icon: icons.EDITAR },
              { id: "projetos_excluir", name: "Excluir", icon: icons.EXCLUIR },
              { id: "projetos_imprimir", name: "Imprimir", icon: icons.IMPRIMIR, },
            ],
          },
          {
            id: "maquinas",
            name: "Máquinas",
            icon: icons.MAQUINAS,
            to: {name: 'MaquinasLista'}, 
            permissao: "maquinas_acessar",
            disabled: false,
            children: [
              { id: "maquinas_acessar", name: "Acessar", icon: icons.ACESSAR, },
              { id: "maquinas_adicionar", name: "Adicionar", icon: icons.ADICIONAR },
              { id: "maquinas_editar", name: "Editar", icon: icons.EDITAR },
              { id: "maquinas_excluir", name: "Excluir", icon: icons.EXCLUIR },
            ],
          },
          {
            id: "componentes",
            name: "Componentes",
            icon: icons.COMPONENTES,
            to: {name: 'ComponentesLista'}, 
            permissao: "componentes_acessar",
            disabled: false,
            children: [
              { id: "componentes_acessar", name: "Acessar", icon: icons.ACESSAR, },
              { id: "componentes_adicionar", name: "Adicionar", icon: icons.ADICIONAR, },
              { id: "componentes_editar", name: "Editar", icon: icons.EDITAR },
              { id: "componentes_excluir", name: "Excluir", icon: icons.EXCLUIR, },
            ],
          },
          {
            id: "pecas",
            name: "Peças",
            icon: icons.PECAS,
            to: {name: 'PecasLista'}, 
            permissao: "pecas_acessar",
            disabled: false,
            children: [
              { id: "pecas_acessar", name: "Acessar", icon: icons.ACESSAR },
              { id: "pecas_adicionar", name: "Adicionar", icon: icons.ADICIONAR },
              { id: "pecas_editar", name: "Editar", icon: icons.EDITAR },
              { id: "pecas_excluir", name: "Excluir", icon: icons.EXCLUIR },
            ],
          },

        ],
      },
      // {
      //   id: 'financeiro', name: 'Financeiro',
      //   children: [
      //     { id: 'contaspagar_acessar', name: 'Contas a Pagar' },
      //     { id: 'contasrecerber_acessar', name: 'Contas a Receber' },
      //     { id: 'caixa_acessar', name: 'Caixa Diário' },
      //   ]
      // },
      { 
        id: "andamento_projeto", 
        name: "Andamento projetos",
        buttom: true,
        visible: false,
        icon: icons.ORDENS_SERVICO, 
        to: {name: 'AndamentoLista'},
        permissao: permissoes.andamento.ACCESAR.id,
        disabled: false,
        permissoes: [
          permissoes.andamento.ACCESAR,
          permissoes.andamento.ADICIONAR,
          permissoes.andamento.EDITAR,
          permissoes.andamento.EXCLUIR,
        ],
      },
      { 
        id: "movimentacoes", 
        name: "Movimentações", 
        icon: icons.MOVIMENTACOES,
        visible: true,
        children: [
          { 
            id: "andamento_projeto", 
            name: "Andamento projetos", 
            icon: icons.ORDENS_SERVICO, 
            to: {name: 'AndamentoLista'},
            permissao: permissoes.andamento.ACCESAR.id,
            disabled: false,
            children: [
              permissoes.andamento.ACCESAR,
              permissoes.andamento.ADICIONAR,
              permissoes.andamento.EDITAR,
              permissoes.andamento.EXCLUIR,
            ],
          },
          // { 
          //   id: "movimento_estoque", 
          //   name: "Movimento de Estoque", 
          //   icon: icons.MOVIMENTO_ESTOQUE, 
          //   to: { name: 'EstoqueLista' },
          //   permissao: "movimento_estoque_acessar",
          //   disabled: false,
          //   children: [
          //     permissoes.estoque.ACCESAR,
          //     permissoes.estoque.ADICIONAR,
          //     permissoes.estoque.EDITAR,
          //     permissoes.estoque.EXCLUIR,
          //   ],
          // },
        ],
      },
      { 
        id: "sistema", 
        name: "Sistema", 
        icon: icons.SISTEMA,
        visible: true,
        children: [
          { 
            id: "usuarios", 
            name: "Usuários", 
            icon: icons.USUARIOS, 
            to: {name: 'UsuariosLista'},
            permissao: "usuarios_acessar",
            disabled: false,
            children: [
              { id: "usuarios_acessar", name: "Acessar", icon: icons.ACESSAR, },
              { id: "usuarios_adicionar", name: "Adicionar", icon: icons.ADICIONAR },
              { id: "usuarios_editar", name: "Editar", icon: icons.EDITAR },
              { id: "usuarios_excluir", name: "Excluir", icon: icons.EXCLUIR },
            ],
          },
          { 
            id: "permissoes_acessar", 
            name: "Permissões", 
            icon: icons.PERMISSOES, 
            to: {name: 'Permissoes'},
            permissao: "permissoes_acessar",
            disabled: false, 
          },
          // { 
          //   id: 'divider', 
          //   name: 'divider',
          // },
          // { 
          //   id: 'configuracoes_acessar', 
          //   name: 'Configurações',
          //   icon: icons.SISTEMA, 
          //   to: { name: 'Permissoes' },
          //   permissao: "configuracoes_acessar",
          // },
        ],
      },
    ],
  },
];
