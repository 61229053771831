<template>
  <z-dialogo ref="dialogo" titulo="Componentes" scrollable dividers no-padding largura="1000px" altura="383px">

    <template v-slot:prepend>
      <v-toolbar flat color="white">
        <v-text-field
          ref="busca"
          v-model="busca"
          rounded
          filled
          outlined
          single-line
          dense
          label="Pesquisar"
          clearable
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keydown.enter="onBuscarPressEnter"
          @focus="onBuscaFocus"
          @blur="is_busca_focused = false"
          @click:clear="limparBusca"
        ></v-text-field>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn small fab color="primary" class="ml-3 mr-1" v-on="on" @click="adicionar">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Componente</span>
        </v-tooltip> -->
      </v-toolbar>
    </template>

    <template v-slot:texto>
      <div ref="topo"></div>
      <z-loading :loading="carregando">

        <v-data-table
          mobile-breakpoint="0"
          disable-pagination
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="itens_busca"
          :loading="carregando"
          fixed-header_
          height_="383px"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr 
                v-for="(item, index) in items" 
                ref="urls" 
                class="apontador noselect" 
                :key="index" 
                :class="{'active': isActive(index)}" 
                @click="selecionar(item)" 
              >
                <td>{{ item.id }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-center">{{ item.pecas_count }}</td>
                <td class="text-center">{{ item.tempo_estimado | horaMinutos }}</td>
              </tr>
            </tbody>
          </template>

        </v-data-table>

      </z-loading>
    </template>

    <template v-slot:actions_left>
      <span class="text-subtitle-2">Componentes encontrados: {{ itens_busca.length }}</span>
    </template>
    <template v-slot:actions_right>
      <v-btn color="primary" @click="fechar()">Fechar</v-btn>
    </template>

  </z-dialogo>
</template>

<script>

import pecasApi from "@/app/services/componentesApi";
import { abrirJanela } from "@/app/helpers/utils";

import buscas_mixin from "@/app/mixins/buscas";

  export default {
    name: 'DialogoBuscarComponentes',

    mixins: [buscas_mixin],

    data() {
      return {
        headers: [
          { text: "Código", value: "id", width: "150px" },
          { text: "Descrição", value: "descricao" },
          { text: "Peças", value: "pecas_count", align: "center" },
          { text: "Tempo Estimado", value: "tempo_estimado", align: "center" },
          // { text: "Estoque", value: "estoque", align: 'right' },
        ],
      };
    },

    methods: {

      async buscar() {
        try {
          // if (this.busca && this.busca.length > 0) {
            this.carregando = true;
            this.itens_busca = await pecasApi.buscar(this.busca);
            this.atual = 0;
            setTimeout(() => {
              this.scrollList();
            }, 100);
          // }
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível efetuar a busca.');
        } finally {
          this.carregando = false;
        }
      },
      
      adicionar() {
        abrirJanela(window.location.origin + '/#/cadastros/pecas/adicionar');
      }
    },
  }
</script>

<style scoped>

</style>