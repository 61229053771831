<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="rota_lista" exact>
          <v-icon left>{{ $icon.VOLTAR }}</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn text :disabled="salvando" @click="$router.push(rota_lista)">Cancelar</v-btn>
      <v-btn class="ml-3" color="primary" :loading="salvando" :disabled="!form_valido" @click="confirmar">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert transition="slide-y-transition" dismissible outlined text type="error" :value="!!error.message" @input="error.message = ''">{{ error.message }} </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">Dados do Componente </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field label="Código" v-model="componente.id" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <z-text-field upper label="Descrição" v-model="componente.descricao" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-time-picker 
                    label="Tempo estimado" 
                    v-model="componente.tempo_estimado"
                    hint="Tempo em horas"
                    persistent-hint
                    hide-picker
                  ></z-time-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

        <adicionar-peca-form :componente="componente" v-if="mostrarPecas"></adicionar-peca-form>

        <v-card class="mb-6" v-if="mostrarPecas">
          <v-card-title class="text-subtitle-2">
            Peças do Componente
            <!-- <v-spacer></v-spacer>
            <v-btn class="ma-0" rounded small color="primary" @click="adicionarPeca">
                <v-icon left>mdi-plus</v-icon>
                Adicionar Peça
            </v-btn> -->
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="pa-0">
            <v-data-table 
              mobile-breakpoint="0" 
              hide-default-footer 
              disable-pagination 
              disable-sort 
              :headers="headers" 
              :items="componente.pecas" 
            >
              
              <template v-slot:item="{ item: peca, index }">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ peca.codigo }}</td>
                  <td>
                    <router-link 
                      class="text-decoration-none" 
                      target="_blank"
                      :to="{ name: 'PecasEditar', params: { id: peca.peca_id } }"
                    >
                      {{ peca.descricao }}
                    </router-link>
                  </td>
                  <td class="text-right">{{ peca.quantidade }} {{ peca.unidade }}</td>
                  <!-- <td class="text-right">{{ peca.subtotal }}</td> -->
                  <td class="text-center">
                    <v-btn icon @click="perguntarExcluir(index)" class="ma-0">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>

        </v-card>

      </z-container>
    </z-scroll-layout>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { clone, pluck } from "@/app/helpers/utils";
import componente from "@/app/store/models/componente";
import { trunc } from "@/app/helpers/conversoes";


import '@/components/buscas';

import * as regras from "@/app/helpers/validacoes";

import AdicionarPecaForm from './AdicionarPecaForm'

const peca = {
  peca_id: '',
  codigo: '',
  descricao: '',
  valor: 0,
  quantidade: 1,
  unidade: 'UN',
  subtotal: 0,
}

export default {
  name: "ComponentesForm",

  components: {
    AdicionarPecaForm
  },

  data() {
    return {
      buscando: false,
      hidedetails: false,
      salvando: false,
      adicionando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      peca: {...peca},

      error: {
        message: "",
      },

      rota_lista: { name: "ComponentesLista" },

      headers: [
        { text: 'Item', value: '', width: '50px' },
        { text: 'Código', value: 'codigo', width: '100px' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        // { text: 'SubTotal', value: 'subtotal', align: 'right' },
        { text: 'Opções', value: '', width: '200px', align: 'center' },
      ],
    };
  },

  computed: {
    // ...mapGetters("listas", ["grupos", "subgrupos"]),
    ...mapState('componentes', ['componente']),


    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Componente";
    },

    mostrarPecas() {
      return !this.$store.state.componentes.componente.id ? false : true;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == 'ComponentesLista') {
      this.ZERAR();
    }
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "ComponentesEditar") {
      if (!this.$store.state.componentes.componente.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }

      // this.componente = clone(this.$store.state.componentes.componente);
    }
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("componentes", ["ZERAR"]),
    ...mapActions("componentes", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          
          await this.atualizar(this.componente);
          this.$router.push(this.rota_lista);

        } else {

          await this.salvar(this.componente);

          this.$router.push({ 
            name: "ComponentesEditar", 
            params: {
              id: this.$store.state.componentes.componente.id
            } 
          });

          // this.componente = clone(this.$store.state.componentes.componente);
        }

        this.$eventbus.notificar("Componente salvo com sucesso");
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.codigo[0];
          return;
        }

        this.$eventbus.notificarErro("Não foi possível salvar este componente. Verifique os dados preenchidos.", error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    async perguntarExcluir(index) {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Remover peça?',
        texto: 'O peça será removida do componente.',
        botoes: ['Cancelar', 'Remover'],
        padrao: 'Remover',
        cor: 'red',
      });

      if (resposta == 'Remover') {
        this.componente.pecas.splice(index, 1);
      }
      
      this.$root.dialogo_pergunta.fechar();
    },

  },
};
</script>

<style scoped></style>
