<script setup>
  
  const props = defineProps({
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-content-copy'
    },
    tooltipText: {
      type: String,
      default: 'Copiar para área de transferência'
    },
  })

  const emit = defineEmits(['click']);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.text)
    emit('click');
  }

</script>

<template>
  <v-tooltip v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        @click="copyToClipboard"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<style lang="scss" scoped>

</style>