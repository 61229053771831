<template>
  <div>

    <z-centralized-toolbar>
      <v-toolbar-items>
        
      <v-btn text color="primary" :to="{name: 'ClientesLista'}" exact>
        <v-icon left>mdi-arrow-left</v-icon>
        Voltar
      </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn text @click="$router.push({ name: 'ClientesLista'})" :disabled="salvando">Cancelar</v-btn>
      <v-btn class="ml-3" color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      
      <v-form ref="formulario" v-model="form_valido" lazy-validation>

        <z-container>

          <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

          <v-card class="mb-5">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Dados do Cliente</h3>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>

              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field label="Código" v-model="cliente.id" readonly></z-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <z-date-picker-menu label="Cadastro" v-model="cliente.data_cadastro" readonly></z-date-picker-menu>
                </v-col>

                <v-col cols="12" sm="8">
                  <z-text-field upper label="Nome" v-model="cliente.nome" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>
              </v-row>

              <v-row dense>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-cep v-model="cliente.cep" :buscarcep="true" @resultado="preencherEndereco"></z-cep>
                </v-col>

                <v-col cols="12" sm="9">
                  <z-text-field label="Endereço" v-model="cliente.endereco"></z-text-field>
                </v-col>

                <v-col cols="12" sm="5">
                  <z-text-field label="Bairro" v-model="cliente.bairro"></z-text-field>
                </v-col>

                <v-col cols="12" sm="5">
                  <z-text-field label="Cidade" v-model="cliente.cidade" ></z-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <z-select :items="$globals.ESTADOS" label="Estado" v-model="cliente.estado"></z-select>
                </v-col>

                <v-col cols="12" sm="12">
                  <z-textarea label="Complemento" placeholder="Complemento do endereço" v-model="cliente.complemento" rows="2"></z-textarea>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field label="Telefone Fixo" v-model="cliente.telefone_fixo" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Telefone Celular" v-model="cliente.telefone_celular" v-mask="$v.TELEFONE_MASK" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <z-text-field caps="lower" label="Email" v-model="cliente.email" required></z-text-field>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Documentos</h3>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-select :items="$globals.TIPO_PESSOA" label="Tipo Pessoa" v-model="cliente.tipo_pessoa"></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-cpf-cnpj label="CPF/CNPJ" v-model="cliente.cpf_cnpj"></z-cpf-cnpj>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="RG" v-model="cliente.rg"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field upper label="Orgão Expedidor" v-model="cliente.orgao_expedidor"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Insc. Estadual" v-model="cliente.inscricao_estadual"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Insc. Municipal" v-model="cliente.inscricao_municipal"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-date-picker-menu birthday label="Nascimento" v-model="cliente.data_nascimento"></z-date-picker-menu>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-select :items="$globals.ESTADO_CIVIL" label="Estado Civil" v-model="cliente.estado_civil"></z-select>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-select :items="$globals.SEXO" label="Sexo" v-model="cliente.sexo"></z-select>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

          <v-card>
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Observações</h3>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <z-textarea label="Observações" placeholder="Observações sobre o  cliente" v-model="cliente.observacoes" rows="7"></z-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </z-container>
      </v-form>
    </z-scroll-layout>


  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { clone } from '@/app/helpers/utils';
import cliente from "@/app/store/models/cliente";

import * as regras from '@/app/helpers/validacoes';

export default {
    name: 'clientes-form',

    data() {
      return {
        cliente: {...cliente},
        salvando: false,
        tab: 0  ,
        form_valido: true,
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio beatae nobis quaerat, officiis velit delectus voluptates obcaecati veniam sunt labore eligendi harum molestiae explicabo nesciunt provident. Voluptatum alias repellendus cum.',
        regras: {...regras},
      };
    },

    computed: {
      ...mapGetters('listas', ['funcionarios']),
      // ...mapState('clientes', ['cliente']),

      titulo() {
        let titulo = (/Editar/).test(this.$route.name) ? 'Editar' : 'Adicionar';
        return titulo + ' ' + 'Cliente';
      }
    },

    beforeRouteLeave(to, from, next) {
      this.ZERAR();
      next();
    },

    async mounted() {
      this.setTitulo(this.titulo);

      if (this.$route.name == "ClientesEditar") {
        if (!this.$store.state.clientes.cliente.id) {
          if (this.$route.params.id) {
            await this.abrir(this.$route.params.id);
          }
        }
      } else {
        this.$store.commit('clientes/ZERAR');
      }

      this.cliente = clone(this.$store.state.clientes.cliente);

      this.$store.dispatch('listas/funcionarios');
      this.$store.dispatch('listas/tipos_clientes');
    },

    methods: {
      ...mapMutations(["setTitulo"]),
      ...mapMutations("clientes", ["ZERAR"]),
      ...mapActions("clientes", ["abrir", "atualizar", "salvar"]),

      async confirmar() {
        try {

          if (!this.$refs.formulario.validate()) {
            return false;
          }

          this.salvando = true;

          if (this.$route.params.id) {
              await this.atualizar(this.cliente);
          } else {
              await this.salvar(this.cliente);
          }
          this.$router.push({ name: 'ClientesLista' });
          this.$eventbus.notificar("Cliente salvo com sucesso.");
        } catch(error) {
          this.$eventbus.notificarErro('Não foi possível salvar este Cliente. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
            this.salvando = false;
        }
      },

      preencherEndereco(endereco) {
          if (endereco.erro) {
              this.cliente.End = "";
              this.cliente.Bairro = "";
              this.cliente.Cidade = "";
              this.cliente.Estado = "";
              // this.cliente.ibge  = '';
          } else {
              this.cliente.endereco = endereco.logradouro.toUpperCase();
              this.cliente.bairro = endereco.bairro.toUpperCase();
              this.cliente.cidade = endereco.localidade.toUpperCase();
              this.cliente.estado = endereco.uf.toUpperCase();
              // this.cliente.ibge  = endereco.ibge;
              this.$refs.ponto_referencia.focus();
          }
      }
    },

    watch: {
      'cliente.cpfcnpj'(val, oldval){
        if (val) {
          this.cliente.tipo_pessoa = val.length > 14 ? 'J' : 'F';
        }
      }
    }
};
</script>

<style scoped>

</style>
