<template>
  <v-container :fluid="fluid || $vuetify.breakpoint.mdAndDown">
    <v-row dense :justify="justify">
      <v-col cols="12" :xl="xl">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'z-container',
    props: {
      fluid: {
        type: Boolean,
        default: false
      },

      justify: {
        type: String,
        default: 'center'
      },

      xl: {
        type: [String, Number],
        default: '9'
      }
    },

  }
</script>

<style scoped>

</style>