<template>
    
  <div>

    <template v-for="(menu, index) in permissoes">

      <v-btn rounded v-if="menu.visible && menu.buttom" :key="index" :to="menu.to" class="ml-3">
        <v-icon>{{ menu.icon }}</v-icon>
        {{menu.name}}
      </v-btn>

      <v-menu v-if="menu.visible" bottom offset-y nudge-bottom="18" transition="slide-y-transition" :key="menu.name">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded :text="removerAcentos(menu.name) != segment" v-bind="attrs" v-on="on" class="text-capitalize font-weight-regular ml-3">
            <v-icon v-if="menu.icon" class="mr-1">{{menu.icon}}</v-icon>
            {{menu.name}}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list nav dense v-if="menu.children">

          <v-subheader class="text-uppercase text--primary font-weight-black">{{menu.name}}</v-subheader>

          <v-list-item-group v-model="item" color="primary">

            <template v-for="(item, i) in menu.children">
              <v-divider v-if="item.name == 'divider'" :key="`${i}a`"></v-divider>

              <v-list-item v-else :value="item.name" :key="i" :to="item.to" :disabled="!$auth.temPermissao(item.permissao) || item.disabled">
                <v-list-item-icon v-if="item.icon">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </v-list-item-group>
        </v-list>
      </v-menu>

    </template>

  </div>

</template>

<script>

  // import menu from "./menu";
  import permissoes from "@/pages/sistema/usuarios/menu_permissoes";
  export default {
    name: 'menu-principal',
    
    data: () => ({
      item: null,
      // menus: menu
    }),

    computed: {
      permissoes() {
        return permissoes[0].children;
      },

      segment() {
        let segments = this.$route.path.substr(1).split('/');
        return segments.length == 1 ? '/': segments[0];
      }
    },

    methods: {
      sair() {
        //
      },

      removerAcentos(str) {
          return str.toLowerCase().normalize("NFD").replace(/[^a-zA-Z\s]/g, "");
      }
    },
  }
</script>

<style scoped>
.theme--dark.v-btn.v-btn--has-bg {
  background-color: #ffffff;
  color: #272727
}
</style>