import horastrabalhadasApi from "@/app/services/horastrabalhadasApi";
import horatrabalhada from "@/app/store/models/horatrabalhada";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    horatrabalhada: {...horatrabalhada},
    horastrabalhadas: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS,
    filtro: {
      projeto_id: 0,
      maquina_id: 0, 
      componente_id: 0
    }
  },

  mutations: {
    LISTAR(state, horastrabalhadas) {
      state.horastrabalhadas = horastrabalhadas;
    },

    ABRIR(state, horatrabalhada) {
      state.horatrabalhada = horatrabalhada;
    },

    ADICIONAR(state, horatrabalhada) {
      state.horastrabalhadas.push(horatrabalhada);
    },

    ATUALIZAR(state, horatrabalhada) {
      let index = state.horastrabalhadas.findIndex(horatrabalhada_ => horatrabalhada_.id == horatrabalhada.id);
      if (index > -1) {
        state.horastrabalhadas.splice(index, 1, horatrabalhada);
      }
    },

    EXCLUIR(state, id) {
      let index = state.horastrabalhadas.findIndex(horatrabalhada => horatrabalhada.id == id);
      if (index > -1) {
        state.horastrabalhadas.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.horatrabalhada = {...horatrabalhada};
    },

    SET_FILTRO(state, filtro) {
      state.filtro = filtro;
    }

  },

  actions: {
    async listar({ commit, state }) {
      let response = await horastrabalhadasApi.listar(state.filtro);
      commit('LISTAR', response);
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let horatrabalhada = await horastrabalhadasApi.abrir(id);
      commit('ABRIR', horatrabalhada);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, horatrabalhada) {
      let novohoratrabalhada = await horastrabalhadasApi.salvar(horatrabalhada);
      commit('ADICIONAR', novohoratrabalhada);
    },

    async atualizar({ commit }, horatrabalhada) {
      await horastrabalhadasApi.atualizar(horatrabalhada);
      commit('ATUALIZAR', horatrabalhada);
    },

    async excluir({ commit }, id) {
      await horastrabalhadasApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
