import ProjetosLista from '@/pages/cadastros/projetos/ProjetosLista'
import ProjetosForm from '@/pages/cadastros/projetos/ProjetosForm'

export default [
  {
    path: '/cadastros/projetos', 
    name: 'ProjetosLista', 
    component: ProjetosLista,
    meta: {layout: 'principal', permissao: 'projetos_acessar'}
  },
  
  {
    path: '/cadastros/projetos/adicionar', 
    name: 'ProjetosAdicionar', 
    component: ProjetosForm,
    meta: {layout: 'principal', permissao: 'projetos_adicionar'}
  },
  
  {
    path: '/cadastros/projetos/:id/editar', 
    name: 'ProjetosEditar', 
    component: ProjetosForm,
    meta: { layout: 'principal', permissao: 'projetos_editar'}
  }
];
