<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{name: 'AndamentoLista'}" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <!-- <v-btn text @click="$router.push({ name: 'AndamentoLista'})" :disabled="salvando">Cancelar</v-btn>
      <v-btn class="ml-3" color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn> -->


      <v-spacer></v-spacer>

      <v-toolbar-items v-show="$vuetify.breakpoint.mdAndUp">
        <z-menu-impressoes :impressoes="impressoes" left></z-menu-impressoes>
      </v-toolbar-items>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">

        <z-container>

          <z-cabecalho>Andamento do Projeto</z-cabecalho>

          <v-card class="mb-5">
            <v-toolbar flat dense>
              <h3 class="text-subtitle-2">Projeto</h3>
              <v-spacer></v-spacer>

              <h3 class="text-subtitle-2 mr-3">Chave Acesso: <b class="text-subtitle-1 font-weight-bold" v-if="projeto.userotp">{{ projeto.userotp.otp }}</b></h3>

              <v-btn id="btnGerarChave" icon :loading="chave_otp_loading" @click="gerarNovaChaveOTP(projeto.id)">
                <v-icon>mdi-reload</v-icon>
                <v-tooltip bottom activator="#btnGerarChave">
                  Gerar nova chave
                </v-tooltip>
              </v-btn>

              <z-copy-clipboard
                bottom
                icon="mdi-content-copy"
                tooltip-text="Copiar link de acesso para área de transferência"
                :text="otpLoginURL(projeto)"
                @click="$eventbus.notificar('Link de acesso copiado para área de transferência.')"
              ></z-copy-clipboard>

              <v-btn v-show="false" id="btnEditarProjeto" icon v-if="$auth.can('clientes_editar')" :to="{ name: 'ProjetosEditar', params: { id: projeto.id } }">
                <v-icon>mdi-pencil</v-icon>
                <v-tooltip bottom activator="#btnEditarProjeto">
                  Editar Projeto
                </v-tooltip>
              </v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    bordered
                    color="error"
                    overlap
                    offset-x="20"
                    offset-y="15"
                    dot
                    :value="projeto.arquivos_count"
                  >
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon 
                      @click="abrirArquivos(projeto)"
                    >
                      <v-icon>{{ $icon.ANEXOS }}</v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>Arquivos</span>
              </v-tooltip>

            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="text--primary pa-3">
              <v-card flat class="pa-3 grey lighten-3 rounded-lg">
                <v-row>
                  <v-col cols="10">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <label for="" class="font-weight-bold">Número do Projeto</label>
                        <div>{{ projeto.id }}</div>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <z-text-field-text label="Nome do Projeto">
                          {{ projeto.descricao }}
                        </z-text-field-text>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <label for="" class="font-weight-bold">Nome do Projeto</label>
                        <div>{{ projeto.descricao }}</div>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <label for="" class="font-weight-bold">Cliente</label>
                        <div>{{ projeto.cliente.nome }}</div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <label for="" class="font-weight-bold">Tempo Estimado</label>
                        <div>{{ projeto.tempo_estimado }}</div>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <label for="" class="font-weight-bold">Data Início</label>
                        <div>{{ projeto.data_inicio   | dataBR }}</div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label for="" class="font-weight-bold">Data Previsão</label>
                        <div>{{ projeto.data_prevista | dataBR }}</div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label for="" class="font-weight-bold">Data Finalização</label>
                        <div>{{ projeto.data_finalizacao | dataBR }}</div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label for="" class="font-weight-bold">Previsão em dias</label>
                        <div>45 dias</div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="2" class="text-center">
                    <z-percentual-projeto :percentual="projeto.percentual_concluido"></z-percentual-projeto>
                  </v-col>
                </v-row>

              </v-card>
            </v-card-text>
          </v-card>

          <z-cabecalho>Máquinas do Projeto</z-cabecalho>

          <template v-for="(maquina, index) in projeto.maquinas">
            <v-card class="mb-6" :key="index">
              <v-card-title class="text-subtitle-2 py-3 d-flex">
                <v-row dense>
                  <v-col cols="12" md="6">
                    <b class="mr-2">MAQUINA:</b> {{ maquina.id }}-{{ maquina.descricao }}
                  </v-col>
                  <v-col cols="12" md="6" class="text-right">
                    <v-badge
                      class="mr-3"
                      bordered
                      color="primary"
                      overlap
                      offset-xx="15"
                      offset-yy="15"
                      :content="maquina.fotos_count"
                      :value="maquina.fotos_count"
                    >
                      <v-btn icon @click="fotosMaquinas(maquina)">
                        <v-icon>mdi-camera</v-icon>
                      </v-btn>
                    </v-badge>
                    
                    <v-btn icon target="_blank" :to="{ name: 'MaquinasEditar', params: {id: maquina.id} }">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-divider></v-divider>

              <v-card-title class="py-3">
                <z-percentual-maquina width="100%" class="text-subtitle-2" :percentual="maquina.percentual_maquina"></z-percentual-maquina>
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text v-if="false">

                  <v-card v-for="(componente, index) in maquina.componentes" outlined class="mb-3 rounded-lg">
                    <v-card-text class="text--primary">
                      <v-row dense>
                        <v-col cols="12" sm="12">
                          <z-text-field-text label="Descrição">
                            {{ componente.descricao }}
                          </z-text-field-text>
                        </v-col>
                        <v-col cols="6" xs="6">
                          <z-text-field-text label="Status">
                            <v-chip
                              v-bind="getCorChip(componente.status)"
                              class="mt-1"
                              outlined
                              small
                              @click="alterarStatus(maquina, componente)"
                            >
                              {{ componente.status }}
                            </v-chip>
                          </z-text-field-text>
                        </v-col>
                        <v-col cols="6" xs="6">
                          <z-text-field-text label="Peças">
                            {{ componente.pecas_count }}
                          </z-text-field-text>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <z-text-field-text label="Tempo Estimado">
                            {{ componente.tempo_estimado | horaMinutos }}
                          </z-text-field-text>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <z-text-field-text label="Horas Trabalhadas">
                            {{ componente.horas_trabalhadas | horaMinutos }}
                            <v-btn icon fab small color="primary" @click="abrirDialogoAdicionarHoras(maquina, componente)" :disabled="componente.status != $globals.STATUS.PRODUCAO">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </z-text-field-text>
                        </v-col>
                      </v-row>
                    </v-card-text>
                     <v-divider></v-divider>
                     <v-card-actions>
                        <v-btn text small color="primary" @click="alterarStatus(maquina, componente)">Mudar Status</v-btn>
                        <v-btn text small color="primary" @click="abrirDialogoAdicionarHoras(maquina, componente)" :disabled="componente.status != $globals.STATUS.PRODUCAO">
                          Adicionar Horas
                        </v-btn>
                     </v-card-actions>
                  </v-card>

              </v-card-text>

              <v-card-text class="pa-0">
                <div class="pa-4 text--primary font-weight-bold">Componentes/Partes</div>
                <v-divider></v-divider>
                <v-data-table 
                  mobile-breakpoint="0" 
                  hide-default-footer 
                  disable-pagination 
                  disable-sort 
                  :headers="headers" 
                  :items="maquina.componentes" 
                >
                  
                  <template v-slot:item="{ item: componente, index }">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ componente.id }} - {{ componente.descricao }}</td>
                      <td class="text-center">
                        <v-chip
                          v-bind="getCorChip(componente.status)"
                          small 
                          @click="alterarStatus(maquina, componente)"
                        >
                          {{ componente.status }}
                        </v-chip>
                      </td>
                      <td class="text-center">{{ componente.pecas_count }}</td>
                      <td class="text-center">{{ componente.tempo_estimado | horaMinutos }}</td>
                      <td class="text-center">
                        {{ componente.horas_trabalhadas | horaMinutos }}
                        <v-btn icon fab small color="primary" @click="abrirDialogoAdicionarHoras(maquina, componente)" :disabled="componente.status != $globals.STATUS.PRODUCAO">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-center">
                        <v-btn icon small @click="imprimirPecas(componente)">
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:body.append>
                    <tr>
                      <td colspan="4"></td>
                      <td class="text-center"><h3>{{ tempoTotalEstimando(maquina) }}</h3></td>
                      <td class="text-center"><h3>{{ tempoTotalTrabalhado(maquina) }}</h3></td>
                    </tr>
                  </template>

                </v-data-table>

                <v-divider></v-divider>

                <!-- <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="adicionarMaquina">Adicionar</v-btn>
                </v-card-actions> -->
              </v-card-text>


            </v-card>
          </template>

        </z-container>
    </z-scroll-layout>

    <dialogo-buscar-maquinas ref="dialogoBuscarMaquinas" @busca:selecionar="selecionarMaquina"></dialogo-buscar-maquinas>
    <dialogo-adicionar-hora ref="dialogoAdicionarHora"></dialogo-adicionar-hora>

    <z-dialogo
      ref="dialogoStatusComponente"
      titulo="Status Componente"
      :dividers="true"
    >
      <template v-slot:texto>
        <v-row>
          <v-col sm="5">
            <v-radio-group hide-details class="mt-0" v-model="componente.status" column label="Status">
              <v-radio :label="$globals.STATUS.AGUARDANDO" :value="$globals.STATUS.AGUARDANDO"></v-radio>
              <v-radio :label="$globals.STATUS.PRODUCAO" :value="$globals.STATUS.PRODUCAO"></v-radio>
              <v-radio :label="$globals.STATUS.FINALIZADO" :value="$globals.STATUS.FINALIZADO"></v-radio>
              <!-- <v-radio label="Atrasado" value="Atrasado" color="red"></v-radio> -->
            </v-radio-group>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions_right>
        <v-btn text @click="$refs.dialogoStatusComponente.fechar()" :disabled="salvando">Cancelar</v-btn>
        <v-btn depressed color="primary" @click="confirmarStatusComponente(maquina, componente)" :loading="salvando" :disabled="salvando">Alterar</v-btn>
      </template>
    </z-dialogo>

    <fotos-maquinas
      ref="dialogoFotosMaquinas"
      :projeto="projeto"
      :maquina="maquina"
    ></fotos-maquinas>

    <arquivos ref="arquivos" :projeto="projeto"></arquivos>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { clone, pluck, TimeToSeconds, SecondsToTimeHMS } from '@/app/helpers/utils';
import projeto from "@/app/store/models/projeto";

import * as regras from '@/app/helpers/validacoes';

import DialogoAdicionarHora from "./DialogoAdicionarHora";
import FotosMaquinas from "../FotosMaquinas";
import Arquivos from '@/pages/arquivos/Arquivos';


import maquinasApi from "@/app/services/maquinasApi"

import config from '@/app/config/app';
const BASE_URL_PRINT = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;

import globals from '@/app/globals';

export default {
    name: 'ProjetosForm',

    components: {
      DialogoAdicionarHora,
      FotosMaquinas,
      Arquivos
    },

    data() {
      return {
        // projeto: {...projeto},
        salvando: false,
        tab: 0  ,
        form_valido: true,
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio beatae nobis quaerat, officiis velit delectus voluptates obcaecati veniam sunt labore eligendi harum molestiae explicabo nesciunt provident. Voluptatum alias repellendus cum.',
        regras: {...regras},

        error: {
          message: "",
        },

        rota_lista: { name: 'ProjetosLista' },

        headers: [
          { text: 'Item', value: '', width: '50px' },
          { text: 'Descrição', value: 'descricao', width: '100%' },
          { text: 'Status', value: 'status', align: 'center' },
          { text: 'Peças', value: '', align: 'center' },
          { text: 'Tempo Estimado', value: 'tempo_estimado', align: 'center' },
          { text: 'Horas Trabalhadas', value: '', width: '150px', align: 'center' },
          { text: 'Imprimir', value: '', width: '80px', align: 'center' },
        ],

        componente: {},
        maquina: {},

        chave_otp_loading: false,
        impressoes: [
          { 
            texto: 'Resumo da produção', 
            acao: 'imprimir_resumo_producao', 
            visible: true, 
            callback: () => null
          },
          { 
            texto: 'Lista de Material', 
            acao: 'imprimir_lista_material', 
            visible: true, 
            callback: () => null
          }
        ],
      };
    },

    computed: {
      ...mapGetters('listas', ['clientes']),
      ...mapState('projetos', ['projeto']),


      mostrarMaquinas() {
        return !this.$store.state.projetos.projeto.id ? false : true;
      }
    },

    beforeRouteLeave(to, from, next) {
      if (to.name == 'ProjetosLista') {
        this.ZERAR();
      }
      next();
    },

    async mounted() {
      if (this.$route.name == "AndamentoProjeto") {
        if (this.$route.params.id) {

          try {
            await this.abrirAndamento(this.$route.params.id);
          } catch (error) {
            this.$eventbus.notificarErro('Não foi possível abrir o Projeto.', error);
          }
        }

        // this.projeto = clone(this.$store.state.projetos.projeto);

      }

      // this.$store.dispatch('listas/clientes');
    },

    methods: {
      ...mapMutations(["setTitulo"]),
      ...mapMutations("projetos", ["ZERAR"]),
      ...mapMutations('horastrabalhadas', ['SET_FILTRO']),

      ...mapActions("projetos", ["abrir", "atualizar", "salvar", "abrirAndamento", "gerarChaveOTP"]),
      ...mapActions("horastrabalhadas", {
          listar: 'listar', 
          excluir: 'excluir', 
          abrirHoras: 'abrir'
        }),


      async confirmar() {
        this.error.message = "";

        if (!this.$refs.formulario.validate()) {
          return false;
        }

        try {
          this.salvando = true;

          if (this.$route.params.id) {
            let projeto = this.transformeProjeto({...this.projeto});

            await this.atualizar(projeto);
            this.$router.push(this.rota_lista);

          } else {
            await this.salvar(this.projeto);
              this.$router.push({ 
                name: "MaquinasEditar", 
                params: {
                  id: this.$store.state.projetos.projeto.id
                } 
              });
          }

          this.$eventbus.notificar("Projeto salvo com sucesso.");
        } catch(error) {
          if (error.status == 422) {
            this.error.message = error.data.errors.codigo[0];
            return;
          }

          this.$eventbus.notificarErro('Não foi possível salvar este Projeto. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      adicionarMaquina() {
        // this.zerarPeca();
        // this.$refs.formulario.resetValidation();
        this.$refs.dialogoBuscarMaquinas.abrir();
      },

      selecionarMaquina(maquina) {
        if (this.jaExiste(this.projeto.maquinas, maquina.id)) {
          this.$eventbus.alertar('Este maquina já foi adicionada a esse projeto.');
          return false;
        }

        this.projeto.maquinas.push(maquina);
        // this.projeto.tempo_estimado = this.tempoTotal;

      },

      async perguntarExcluir(index) {
        let resposta = await this.$root.dialogo_pergunta.abrir({
          titulo: 'Remover maquina?',
          texto: 'O maquina será removido da máquina.',
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
        });

        if (resposta == 'Remover') {
          this.projeto.maquinas.splice(index, 1);
          this.projeto.tempo_estimado = this.tempoTotal;
        }
        
        this.$root.dialogo_pergunta.fechar();
      },

      transformeProjeto(projeto) {
        let maquinas = pluck(projeto.maquinas, 'id');
        projeto.codigo_maquinas = maquinas;
        delete projeto.maquinas;
        return projeto;
      },

      jaExiste(maquinas, codigo) {
        const index = maquinas.findIndex((maquina) => maquina.id === codigo);
        return index > -1 ? true : false;
      },

      async abrirDialogoAdicionarHoras(maquina, componente) {
        let filtro = {
          projeto_id: this.projeto.id,
          maquina_id: maquina.id, 
          componente_id: componente.id
        }

        this.SET_FILTRO(filtro)
        await this.listar();
        this.$refs.dialogoAdicionarHora.abrir();
      },

      getCorChip: function(status) {
        if (status == globals.STATUS.AGUARDANDO) {
          return {
            color: 'blue-grey lighten-4',
            dark: false
          };
        }

        if (status == globals.STATUS.PRODUCAO) {
          return {
            color: 'blue',
            dark: true
          };
        }

        if (status == globals.STATUS.FINALIZADO) {
          return {
            color: 'green',
            dark: true
          };
        }

        if (status == globals.STATUS.ATRASADO) {
          return {
            color: 'red',
            dark: true
          };
        }

        return {
          color: 'blue-grey lighten-4',
          dark: false
        };
      },

      alterarStatus(maquina, componente) {
        this.maquina = {...maquina};
        this.componente = {...componente};
        this.$refs.dialogoStatusComponente.abrir();
      },

      async confirmarStatusComponente(maquina, componente) {
        try {
          this.salvando = true;

          let projeto_maquina_componente = {
            projeto_id: this.projeto.id,
            maquina_id: maquina.id, 
            componente_id: componente.id,
            status: componente.status
          }

          let percentual = await maquinasApi.atualizarStatusComponente(projeto_maquina_componente);
          maquina = this.projeto.maquinas.find(maquina => maquina.id == this.maquina.id);
          componente = maquina.componentes.find(componente => componente.id == this.componente.id);
          componente.status = this.componente.status;
          this.projeto.percentual_concluido = percentual.percentual_projeto;
          maquina.percentual_maquina = percentual.percentual_maquina;
          this.$eventbus.notificar("Status atualizado.");
        } catch (error) {
          this.$eventbus.notificarErro("Não foi possível alterar o status do componente.", error);
        } finally {
          this.$refs.dialogoStatusComponente.fechar();
          this.salvando = false;
        }
      },

      tempoTotalEstimando(maquina) {
        let segundos = pluck(maquina.componentes, 'tempo_estimado')
        .map(item => {
          return TimeToSeconds(item);
        }).reduce((item, soma) => {
          return soma + item;
        },0);

        return SecondsToTimeHMS(segundos).substring(0,5);
      },
      
      tempoTotalTrabalhado(maquina) {
        let segundos = pluck(maquina.componentes, 'horas_trabalhadas')
        .map(item => {
          return TimeToSeconds(item);
        }).reduce((item, soma) => {
          return soma + item;
        },0);

        return SecondsToTimeHMS(segundos).substring(0,5);
      },

      async gerarNovaChaveOTP(projeto_id) {

        try {
          this.chave_otp_loading = true;
          await this.gerarChaveOTP(projeto_id);
        } catch (error) {
          this.$eventbus.notificarErro("Erro ao gerar nova chave de acesso ao projeto", error);
        } finally {
          this.chave_otp_loading = false;
        }
      },

      fotosMaquinas(maquina) {
        this.maquina = maquina;
        setTimeout(() => {
          this.$refs.dialogoFotosMaquinas.abrir();
        }, 100);
      },

      imprimirPecas(componente) {
        setTimeout(() => {
          let urlImpressao = `${BASE_URL_PRINT}/impressoes/componente/${componente.id}/pecas`;
          this.abrirJanela(urlImpressao);
        }, 300);
      },

      otpLoginURL(projeto) {
        return `${window.location.origin}/#/cliente/login/${projeto.userotp?.otp} `;
      },

      async abrirArquivos(projeto) {
        // await this.abrir(guia.id);
        this.$refs.arquivos.abrir();
      },
    },

    watch: {
      'projeto.cpfcnpj'(val, oldval){
        if (val) {
          this.projeto.tipo_pessoa = val.length > 14 ? 'J' : 'F';
        }
      }
    }
};
</script>

<style scoped>

</style>
