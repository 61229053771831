<template>
  <z-dialogo ref="dialogoHorasTrabalhadas" titulo="Horas Trabalhadas" altura="300px" largura="800" dividers persistent>

    <template v-slot:titlebuttons>
      <v-btn rounded small color="primary" @click="adicionar" v-show="!exibir_form">
        <v-icon left>mdi-plus</v-icon>
        Adicionar Horas
      </v-btn>
    </template>


    <template v-slot:conteudo>

      <div v-if="exibir_form">
        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-row>

            <v-col cols="12" sm="12" v-if="isAdmin">
              <z-select hide-details label="Usuários" :items="usuarios" v-model="horatrabalhada.user_id" :rules="[regras.campoObrigatorio]"></z-select>
            </v-col>

            <v-col cols="12" sm="12">
              <z-time-picker 
                label="Tempo Trabalhado" 
                v-model="horatrabalhada.tempo_trabalhado"
                hint="Tempo em horas. Ex.: 1:30 ou 0:30"
                persistent-hint
                hide-picker
                :rules="[regras.campoObrigatorio]"
              ></z-time-picker>
            </v-col>


            <v-col cols="12" sm="12">
              <z-textarea 
                v-model="horatrabalhada.trabalho_executado" 
                label="Trabalho Executado" 
                placeholder="Informe o trabalho que foi executado" 
                rows="3"
                hide-details
                :rules="[regras.campoObrigatorio]"
              ></z-textarea>
            </v-col>
          </v-row>
        </v-form>
      </div>
      
      <v-data-table v-else
        mobile-breakpoint="0"
        hide-default-footer 
        disable-pagination 
        disable-sort 
        :headers="headers"
        :items="horastrabalhadas"
      >
        <template v-slot:item.data="{ value: data }">
          {{ data | dataBR }}
        </template>
        <template v-slot:item.tempo_trabalhado="{ value: tempo_trabalhado }">
          {{ tempo_trabalhado | horaMinutos }}
        </template>

        <template v-slot:item.opcoes="{ item: horatrabalhada }">
          <v-btn icon @click="editar(horatrabalhada)">
            <v-icon>{{ $icon.EDITAR }}</v-icon>
          </v-btn>

          <z-delete
            titulo="Remover hora trabalhada"
            botaoSim="Remover"
            botaoNao="Cancelar"
            cor-botao-sim="red"
            :loading="excluindo"
            @click:sim="confirmarExcluir(horatrabalhada)"
          >
            A hora trabalhada será removida. Deseja remover?
          </z-delete>
        </template>

      </v-data-table>

    </template>
    <template v-slot:actions_right>
      <div v-if="exibir_form">
        <v-btn text @click="exibir_form=!exibir_form">Cancelar</v-btn>
        <v-btn class="ml-2" color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido" >Salvar</v-btn>
      </div>
      <v-btn v-else color="primary" @click="$refs.dialogoHorasTrabalhadas.fechar()">Fechar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

  import horatrabalhada from "@/app/store/models/horatrabalhada";
  import * as regras from "@/app/helpers/validacoes";


  export default {
    name: 'DialogoAdicionarHora',

    data() {
      return {
        data: 'value',
        horatrabalhada: {...horatrabalhada},

        exibir_form: false,

        headers: [
          { text: "Quem", value: "user.name" },
          { text: "Data", value: "data" },
          { text: "Duração", value: "tempo_trabalhado", align: "center" },
          { text: "Trabalho Executado", value: "trabalho_executado" },
          {
            text: "Opções",
            value: "opcoes",
            width: "200px",
            align: "center",
            sortable: false,
          },
        ],

        regras: { ...regras },
        salvando: false,
        form_valido: true,
        excluindo: false
      }
    },

    computed: {
      ...mapState('horastrabalhadas', ['horastrabalhadas', 'filtro']),
      ...mapGetters('listas', ['usuarios']),

      name() {
        return this.data;
      },

      isAdmin(){
        return this.$auth.isAdmin();
      },
    },

    methods: {
      ...mapMutations('horastrabalhadas', ['ZERAR', 'SET_FILTRO']),
      ...mapActions('horastrabalhadas', ['listar', 'excluir', 'salvar', 'atualizar']),
      ...mapActions("projetos", ["abrir"]),

      async confirmar() {
        // this.error.message = "";

        if (!this.$refs.formulario.validate()) {
          return false;
        }

        try {
          this.salvando = true;

          if (this.horatrabalhada.id) {
            await this.atualizar(this.horatrabalhada);
          } else {
            let horatrabalhada = {
              ...this.horatrabalhada,
              ...this.filtro
            }
            await this.salvar(horatrabalhada);
          }

          this.$eventbus.notificar("Horas salvas com sucesso");
          this.exibir_form = false;
          this.$store.dispatch('projetos/abrirAndamento', this.filtro.projeto_id)
        } catch (error) {
          if (error.status == 422) {
            this.error.message = error.data.errors.codigo[0];
            return;
          }

          this.$eventbus.notificarErro("Não foi possível salvar os dados. Verifique os dados preenchidos.", error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      async abrir() {
        this.$store.dispatch('listas/usuarios');
        this.exibir_form = false;
        // await this.listar({...this.filtro});
        this.$refs.dialogoHorasTrabalhadas.abrir();
      },

      fechar() {
        this.ZERAR();
        this.$refs.dialogoHorasTrabalhadas.fechar();
      },

      adicionar() {
        this.horatrabalhada = {...horatrabalhada};
        this.exibir_form = true;
      },

      editar(horatrabalhada) {
        this.horatrabalhada = horatrabalhada;
        this.exibir_form = true;
      },

      async confirmarExcluir(horatrabalhada) {
        try {
          this.excluindo = true;
          await this.excluir(horatrabalhada.id);
          this.$eventbus.notificar("Hora trabalhada excluída com sucesso!");
          this.$store.dispatch('projetos/abrirAndamento', this.filtro.projeto_id)
        } catch (error) {
          this.$eventbus.notificarErro("Não foi possível excluir a hora trabalhada!", error);
          throw error;
        } finally {
          this.excluindo = false;
        }
      },

    },

    watch: {
      data(newValue, oldValue) {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>