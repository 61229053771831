<template>
  <v-menu v-model="menu" :close-on-content-click="false" :close-on-click="closeOnClick" left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-card :width="largura">
      <v-card-title v-show="titulo">{{ titulo }}</v-card-title>

      <v-card-text>
        <slot>{{ texto }}</slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed ref="btnNao" @click="responder(false)" :disable="props.loading">{{ botaoNao }}</v-btn>
        <v-btn depressed dark ref="btnSim" :color="corBotaoSim" :loading="props.loading" @click="responder(true)">{{ botaoSim }}</v-btn >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup>

  import { ref, watch } from 'vue';

  const props = defineProps({
    titulo: {
      type: String,
      default: null,
    },
    texto: {
      type: String,
      default: null,
    },
    largura: {
      type: String,
      default: "400px",
    },
    dividers: {
      type: Boolean,
      default: false,
    },
    botaoSim: {
      type: String,
      default: "Sim",
    },
    botaoNao: {
      type: String,
      default: "Não",
    },
    corBotaoSim: {
      type: String,
      default: "primary",
    },

    loading: {
      type: Boolean,
      default: false,
    },
  })

  let menu = ref(false);
  let closeOnClick = ref(true);
  
  const emit = defineEmits(['click:sim', 'click:nao'])

  const responder = (resposta) => {
    if (resposta == false) {
      menu.value = false;
      emit('click:nao')
    } else {
      closeOnClick.value = false;
      emit('click:sim')
    }
  }

  watch(() => props.loading, (newValue, oldValue) => {
    if (oldValue == true && newValue == false) {
      menu.value = false;
      closeOnClick.value = true;
    }
  })
</script>

<style lang="scss" scoped>

</style>