<template>
  <v-app>
    <v-main :class="$vuetify.breakpoint.mdAndUp ? 'grey lighten-3' : 'white'">
        <v-container fill-height>
          <v-row class="fill-height_" justify="center" align_="center">
            <v-col cols="12" sm="6" md="6" lg="4" xl="3">
              <v-slide-y-transition>
                <v-card v-show="show" :flat="$vuetify.breakpoint.smAndDown">
                  <!-- <v-card-text>
                    <v-row justify="center">
                      <img src="static/organize-verde/96x96.png">
                    </v-row>
                  </v-card-text> -->

                  <v-container class="pa-6">
                    <v-form v-model="form_valido" ref="form">
                      <v-row justify="center" class="mb-5">
                        <h1 class="headline">Acesso ao Sistema</h1>
                      </v-row>
                      <v-row justify="center" class="mb-8">
                        <v-avatar color="primary" size="120">
                          <v-icon dark size="70">mdi-lock</v-icon>
                        </v-avatar>
                      </v-row>
                      <v-row justify="center" dense class="mb-3">
                        <v-col cols="12" sm="12" md="12">
                          <z-text-field
                            v-model="usuario.email"
                            ref="usuario"
                            color="primary"
                            label="Usuário"
                            required
                            :autofocus="$vuetify.breakpoint.mdAndUp"
                            placeholder=" "
                            prepend-icon="mdi-account"
                            :rules="regras.email"
                            :error="erros.email.length > 0"
                            :error-messages="erros.email"
                          ></z-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <z-text-field
                            v-model="usuario.password"
                            ref="senha"
                            color="primary"
                            label="Senha"
                            placeholder=" "
                            prepend-icon="mdi-lock"
                            required
                            :append-icon="mostrar_senha ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="mostrar_senha ? 'text' : 'password'"
                            :rules="regras.password"
                            :error="erros.password.length > 0"
                            :error-messages="erros.password"
                            @click:append="mostrar_senha = !mostrar_senha"
                            @keypress.enter="login"
                          ></z-text-field>
                        </v-col>
                      </v-row>


                      <v-row justify="center" dense>
                        <v-col align="center">
                          <v-btn block_ min-width="200px" rounded color="primary" dark @click="login">Entrar</v-btn>
                        </v-col>
                      </v-row>
                      <v-col cols="12" class="text-center text-caption pa-0">
                        {{ this.$config.APP_VERSION }}
                      </v-col>
                      <v-row justify="center" dense>
                        <v-col align="center">
                          <a href="" @click.prevent="$router.push({ name: 'LoginOTP' })" target="_blank">Clique para acessar como cliente</a>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card>
              </v-slide-y-transition>
            </v-col>
          </v-row>
        </v-container>
      <!-- <v-img height="100vh" min-height="100vh" lazy-src="/img/img_login.jpg" src="/img/img_login.jpg" gradient="to top right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)">
      </v-img> -->
    </v-main>

    <z-aguardar ref="aguardar" color="primary"></z-aguardar>
  </v-app>
</template>

<script>
// import UsuariosService from '../services/usuarios';

import config from "@/app/config/app";

export default {
  data() {
    return {
      usuario: {
        email: "",
        password: "",
      },
      erros: {
        email: [],
        password: [],
      },
      dialog: false,
      form_valido: false,
      regras: {
        email: [(v) => !!v || "Informar o nome do usuário"],
        password: [(v) => !!v || "Informar a senha do usuário"],
      },
      mostrar_senha: false,
      show: false,
      config,
      items: [{ title: "Click Me" }, { title: "Click Me" }, { title: "Click Me" }, { title: "Click Me 2" }],
    };
  },

  mounted() {
    if (!config.IS_PRODUCTION) {
      this.usuario = {
        email: "admin",
        password: "superadmin123",
      };
    }

    this.show = true;
  },
  methods: {
    login() {
      // this.ativarRegras();

      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.$refs.aguardar.abrir();
          this.zerarErros();
          this.$auth
            .login(this.usuario)
            .then((response) => {
              this.limparForm();
              this.$router.push({ path: "/" });
              this.$refs.aguardar.fechar();
            })
            .catch((error) => {
              // this.limparForm();
              this.$auth.logout();
              this.$refs.aguardar.fechar();
              // this.erros.password.push("Usuário ou senha inválidos");
              this.exibirErrosServidor();
              this.$refs.senha.focus();
            });
        }
      });
    },

    zerar() {
      this.usuario = {
        email: "",
        password: "",
      };
    },

    zerarErros() {
      this.erros = {
        email: [],
        password: [],
      };
    },

    ativarRegras() {
      this.regras = {
        email: [(v) => !!v || "O campo email é obrigatório", (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "O campo e-mail é inválido"],
        password: [
          (v) => !!v || "O campo senha é obrigatório",
          // (v) => v && v.length >= 4 || 'Mínimo 4 caracteres'
        ],
      };
    },

    processarErrosServidor(erros) {
      if (erros.email) {
        this.erros.email = erros.email;
        console.log("Erro no email: ", this.erros.email);
      }
      if (erros.password) {
        this.erros.password = erros.password;
        console.log("Erro no password");
      }
    },

    exibirErrosServidor() {
      this.erros.password.push("Usuário ou senha inválidos");
      setTimeout(() => {
        this.erros.password = [];
      }, 3000);
    },

    limparForm() {
      this.$refs.form.reset();
      this.regras = {};
    },
  },
};
</script>

<style>
.topo {
  margin-top: 50px;
}

.glass {
  /* background: rgba(255, 255, 255, 0.1) !important; */
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1)) !important;
  /* border-radius: 2rem !important; */
  z-index: 19;
  backdrop-filter: blur(100rem) !important;
}
</style>
