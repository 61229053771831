import createAuth from '@/app/core/AuthCore'

const auth = createAuth();

export function guards (to, from, next) {

  if (to.meta.public) {
    next();
    return false;
  }

  console.log(to.meta);
  // if (to.meta.type == 'otp' && !auth.checkOTP()) {
  //   next({ name: 'LoginOTP' });
  //   return false;
  // }

  if (!auth.check()) {
    next({ name: 'Login' });
    return false;
  }

  if (!to.meta?.permissao) {
    next();
    return false;
  }

  auth.can(to.meta.permissao) ? next() : next({ name: 'Home' });

  // next();

}