import ComponentesLista from '@/pages/cadastros/componentes/ComponentesLista'
import ComponentesForm from '@/pages/cadastros/componentes/ComponentesForm'

export default [
  {
    path: '/cadastros/componentes',
    name: 'ComponentesLista',
    component: ComponentesLista,
    meta: { permissao: 'componentes_acessar' }
  },
  {
    path: '/cadastros/componentes/adicionar',
    name: 'ComponentesAdicionar',
    component: ComponentesForm,
    meta: { permissao: 'componentes_adicionar' }
  },
  {
    path: '/cadastros/componentes/:id/editar',
    name: 'ComponentesEditar',
    component: ComponentesForm,
    meta: { permissao: 'componentes_editar' }
  },
];
