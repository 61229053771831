export function clone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function trunc(valor, decimais = 2) {
  let dec = Math.pow(10, decimais);
  return Number((Math.round(valor * dec) / dec).toFixed(decimais));
}

export function abrirJanela(url) {
  window.open(url, '_blank');
}

export function copyText(elemento_id) {
  window.getSelection().selectAllChildren(document.getElementById(elemento_id));
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
}

export function hoje() {
  let data = new Date();
  return data.getFullYear() + '-' + (data.getMonth() + 1).toString().padStart(2, '0') + '-' + data.getDate().toString().padStart(2, '0');
}

export function hora() {
  return new Date().toLocaleTimeString().substr(0, 5);
}

export function setFocus(field, timeout = 0) {
  setTimeout(() => {
    field.focus();
  }, timeout);
}

export function selectText(field) {
  setTimeout(() => {
      this.field.selectionStart = 0;
      this.field.selectionEnd   = this.field.value.length;
  }, 0)
}

export function parsePermissoes(menu) {

  const permissoes_rotas = [];
  menu.forEach(item => {

    if (item.itens?.length > 0) {
      item.itens.forEach(element => {
        if (!element.disabled) {
          permissoes_rotas.push({
            name: element.to?.name,
            permissao: element.permissao
          });
        }
      });
    }
  })

  return permissoes_rotas;
}

export function pluck(items, key) {
  if (!items) return [];
  return items.map(index => index[key]);
}

export function TimeToSeconds(time) {
  if (!time) return

  let time_arr = time.split(':');
  let horas = Number(time_arr[0]);
  let minutos = Number(time_arr[1]);
  let segundos = Number(time_arr[2]);

  horas = horas * 60 * 60;
  minutos = minutos * 60;
  let segundos_totais = horas + minutos + segundos;
  return segundos_totais;
}

export function SecondsToTimeHMS(sec) {
  let sec_num = Number(sec);
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}
