<template>
  <v-dialog v-model="exibir" :max-width="largura" v-bind="$attrs" :eager="eager">
    <v-card :tile="tile">
      <v-card-title v-show="titulo" class="pa-4" :class="[titleColor]">
        <h4 class="text-subtitle-2">{{ titulo }}</h4>
        <v-spacer v-if="$slots.titlebuttons"></v-spacer>
        <slot name="titlebuttons"></slot>
      </v-card-title>

      <v-divider v-if="dividers"></v-divider>

      <slot name="prepend"></slot>

      <v-divider v-if="$slots.prepend"></v-divider>

      <v-card-text :style="alturaCard" :class="classes">
        <slot name="texto">
          {{ texto }}
        </slot>
        <slot name="conteudo">
          {{ texto }}
        </slot>

        <div id="scrollEnd"></div>
      </v-card-text>

      <v-divider v-if="dividers"></v-divider>

      <slot name="append"></slot>

      <v-divider v-if="$slots.append"></v-divider>

      <v-card-actions v-if="$slots.actions_left || $slots.actions_right">
        <slot name="actions_left"></slot>
        <v-spacer></v-spacer>
        <slot name="actions_right"></slot>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "z-dialogo",

  props: {
    titulo: {
      type: String,
      default: null,
    },
    texto: {
      type: String,
      default: null,
    },
    largura: {
      type: String,
      default: "400px",
    },
    dividers: {
      type: Boolean,
      default: false,
    },
    altura: {
      type: String,
      default: null,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    titleColor: {
      type: String,
      default: "",
    },
    tile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      exibir: false,
    };
  },

  methods: {
    abrir() {
      this.exibir = true;
      this.$emit("abrir");
    },

    fechar() {
      this.exibir = false;
      this.$emit("fechar");
    },

    scrollEnd() {
      const scrollEnd = document.getElementById("scrollEnd");
      if (scrollEnd) {
        scrollEnd.scrollIntoView();
      }
    },
  },

  computed: {
    alturaCard() {
      if (this.altura) {
        return {
          minHeight: this.altura,
          height: this.altura
        }
      }
      return "";
    },
    classes() {
      return {
        "pa-0": this.noPadding == true,
        "pa-4": this.noPadding == false,
      };
    },
  },
};
</script>

<style scoped>
</style>
