<template>
  <div>
    <v-container >
      <h1>{{ m }}</h1>
    </v-container>
  </div>
</template>

<script>
  export default {
    props: {
      m: {
        type: String,
        default: 'Início'
      }
    }
  }
</script>

<style scoped>

</style>
