import MaquinasLista from '@/pages/cadastros/maquinas/MaquinasLista'
import MaquinasForm from '@/pages/cadastros/maquinas/MaquinasForm'

export default [
  {
    path: '/cadastros/maquinas',
    name: 'MaquinasLista',
    component: MaquinasLista,
    meta: { permissao: 'maquinas_acessar' }
  },
  {
    path: '/cadastros/maquinas/adicionar',
    name: 'MaquinasAdicionar',
    component: MaquinasForm,
    meta: { permissao: 'maquinas_adicionar' }
  },
  {
    path: '/cadastros/maquinas/:id/editar',
    name: 'MaquinasEditar',
    component: MaquinasForm,
    meta: { permissao: 'maquinas_editar' }
  },
];
