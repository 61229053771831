<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    rounded
    outlined
    single-line
    dense
    label="Pesquisar"
    clearable
    hide-details
    prepend-inner-icon="mdi-magnify"
    :autofocus="$vuetify.breakpoint.mdAndUp"
    :style="style"
    @focus="onFocus"
  ></v-text-field>
</template>

<script>
import form_mixin from "./form_mixin";

export default {
  mixins: [form_mixin],
  props: {
    select: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: '250px'
    },
  },

  computed: {
    style() {
      return {
        maxWidth: this.width,
        minWidth: this.width,
      } 
    }
  },
  methods: {
    onFocus(event) {
      if (this.select) {
        setTimeout(function () {
          event.target.select();
        }, 0);
      }
    },
  },
};
</script>

<style scoped>
</style>