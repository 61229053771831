export default {

  cliente_id: null,
  descricao: '',
  data_inicio: '',
  data_finalizacao: '',
  duracao: 0,
  valor: 0,
  tempo_estimado: 0,
  cliente: {
    nome: ''
  },
  userotp: {
    otp: null
  }
}
