<template>
  <div>
    <z-centralized-toolbar v-if="$route.name == 'AndamentoLista'">
      <v-toolbar-items>
        <!-- <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-tools</v-icon>Adicionar
        </v-btn> -->

        <v-btn
          text
          id="btn-atualizar"
          :loading="$store.state.loading"
          @click="refresh"
        >
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        class="mr-4"
        select
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>

      <!-- <z-btn-toggle
        v-model="filtro"
        v-show="$vuetify.breakpoint.mdAndUp"
        class="ml-4" 
        mandatory 
        dense
        :loading="$store.state.loading" 
        :itens="opcoesFiltro" 
        @change="refresh"
      ></z-btn-toggle> -->
    </z-centralized-toolbar>

    <v-divider v-if="$route.name == 'AndamentoLista'"></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <div class="d-flex justify-center mb-4">
          <z-dropdown-button
            v-model="filtro" 
            :items="items_dropdown" 
            @input="refresh"
          ></z-dropdown-button>
        </div>

        <z-cabecalho v-if="$route.name == 'AndamentoLista'" :sub-title="`Projetos: ${projetos.length}`">{{ $store.state.titulo_tela }}</z-cabecalho>
        <z-cabecalho v-else>Projetos</z-cabecalho>

        <v-row>
          <v-col cols="12" sm="4" md="3" v-for="(projeto, index) in projetos" :key="index">
            <v-card>
              <v-card-title class="text-subtitle-2 text-truncate d-block">
                {{ projeto.id }} - {{ projeto.descricao }}
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text class="text-center">

                <v-row>
                  <v-col cols="4">
                    <z-percentual-projeto :percentual="projeto.percentual_concluido"></z-percentual-projeto>
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <h4>Máquinas: {{ projeto.maquinas_count }}</h4>
                    <h4>Chave: <b v-if="projeto.userotp">{{ projeto.userotp.otp }}</b></h4>
                    <h4>Início: <b>{{ projeto.data_inicio | dataBR }}</b></h4>
                    <h4>Previsão: <b>{{ projeto.data_prevista | dataBR }}</b></h4>
                    <!-- <h3>Componentes: {{ projeto.maquinas.componentes.length }}</h3> -->
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <z-copy-clipboard
                  v-if="projeto.userotp"
                  bottom
                  icon="mdi-content-copy"
                  tooltip-text="Copiar link de acesso para área de transferência"
                  :text="otpLoginURL(projeto)"
                  @click="$eventbus.notificar('Link de acesso copiado para área de transferência.')"
                ></z-copy-clipboard>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizar(projeto)"
                    >Visualizar</v-btn>
                  </template>
                  <span>Visualizar</span>
                </v-tooltip>
              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>

      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemoverPeca"
      titulo="Remover peça"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      A peça será removida do sistema. Deseja remover?
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "AndamentoLista",

  data() {
    return {
      headers: [
        { text: "Código", value: "codigo", width: "150px" },
        { text: "Descrição", value: "descricao" },
        { text: "Estoque", value: "estoque", align: "right" },
        { text: "Unidade", value: "unidade", align: "right" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      peca_codigo: "",
      filtro: "producao",
      opcoesFiltro: [
        { descricao: "Todos", value: "" },
        { descricao: "Aguardando", value: "aguardando" },
        { descricao: "Em produção", value: "producao" },
        { descricao: "Finalizados", value: "finalizado" },
      ],
      items_dropdown: [
        { text: 'Todos', value: '' },
        { text: 'Aguardando', value: 'aguardando' },
        { text: 'Em produção', value: 'producao' },
        { text: 'Finalizados', value: 'finalizado' },
      ]
    };
  },

  computed: {
    ...mapState(["loading"]),
    ...mapState("projetos", ["projetos", "peca", "total_itens"]),

    busca: {
      get() {
        return this.$store.state.projetos.busca;
      },
      set(busca) {
        this.$store.commit("projetos/SET_BUSCA", busca);
      },
    },
  },

  beforeRouteLeave (to, from, next) {
    this.busca = '';
    next();
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Andamento Projetos");
  },

  methods: {
    ...mapMutations(["setTitulo", "LOADING_OFF"]),
    ...mapActions("projetos", ["listar", "excluir", "abrirAndamento"]),

    async refresh() {
      try {
        // throw Error('Isso é um erro');
        this.$store.commit("projetos/SET_ITEMSPERPAGE", 0);
        await this.listar(this.filtro);
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível listar os projetos.", error);
      } finally {
        this.LOADING_OFF();
      }
    },
    
    buscar() {
      this.$store.commit("projetos/SET_PAGE", 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit("projetos/SET_PAGE", 1);
      this.refresh();
    },

    async visualizar(projeto) {
      try {

        await this.abrirAndamento(projeto.id);
        this.$router.push({ name: "AndamentoProjeto", params: { id: projeto.id } });
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir o projeto.", error);
      } finally {
        this.LOADING_OFF();
      }
    },
    
    setColor(percentual) {

      if (percentual <= 25) {
        return 'red';
      }

      if (percentual > 25 && percentual <= 50) {
        return 'yellow';
      }

      if (percentual > 50 && percentual <= 75) {
        return 'blue';
      }

      if (percentual > 75) {
        return 'green';
      }
    },

    otpLoginURL(projeto) {
      if (!projeto.userotp) {
        return '';
      }
      return `${window.location.origin}/#/cliente/login/${projeto.userotp.otp}`;
    }
  },
};
</script>

<style scoped>
</style>
