<template>
  <div>

    <z-centralized-toolbar>
      <v-toolbar-items>
              
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar" :disabled="!$auth.can('clientes_adicionar')">
          <v-icon left>{{ $icon.PROJETOS }}</v-icon>Adicionar Projeto
          <v-tooltip bottom activator="#btn-adicionar">
            Adicionar Projeto
          </v-tooltip>
        </v-btn>

        <v-btn id="btn-atualizar" text small @click="refresh" :loading="$store.state.loading">
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">

      <z-container>
        
        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>
        
        <v-card>
          <v-data-table
            :options.sync="paginacao"
            v-bind="vTableOptions"
          >
            <template v-slot:item="{ item: projeto }">
              <tr>
                <td>{{ projeto.id }}</td>
                <td>
                  <router-link class="text-decoration-none" :to="{ name: 'ProjetosEditar', params: { id: projeto.id } }">{{ projeto.descricao }}</router-link>
                </td>
                <td>{{ projeto.cliente && projeto.cliente.nome }}</td>
                <td class="text-center text-no-wrap">{{ projeto.data_inicio | dataBR }}</td>
                <td class="text-center text-no-wrap">{{ projeto.data_prevista | dataBR }}</td>
                <td class="text-center text-no-wrap">
                  <v-chip v-if="!projeto.data_finalizacao" color="red" small dark>Produzindo</v-chip>
                  <span v-else>{{ projeto.data_finalizacao | dataBR }}</span>
                </td>
                <td class="text-center text-no-wrap">
                  <v-progress-linear :value="projeto.percentual_concluido" rounded height="15" :color="setColor(projeto.percentual_concluido)">
                    <strong>{{ projeto.percentual_concluido }}%</strong>
                  </v-progress-linear>
                </td>
                <td class="text-center text-no-wrap">{{ projeto.maquinas_count }}</td>
                <td class="text-center text-no-wrap">
                  <v-btn icon @click="editar(projeto)" v-if="$auth.can('clientes_editar')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(projeto)" v-if="$auth.can('clientes_excluir')">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

      </z-container>

    </z-scroll-layout>


    <z-dialogo-sim-nao ref="Projeto" titulo="Remover projeto?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O projeto será removido do sistema.
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: 'ProjetosLista',

  data() {
    return {
      headers: [
        { text: 'Código', value: 'id', width: '90px' },
        { text: 'Projeto', value: 'descricao' },
        { text: 'Cliente', value: 'cliente.nome' },
        { text: 'Início', value: 'data_inicio', align: 'center' },
        { text: 'Previsão', value: 'data_previsao', align: 'center' },
        { text: 'Finalização', value: 'data_finalizacao', align: 'center' },
        { text: 'Percentual Conluído', value: 'percentual_concluido', align: 'center' },
        { text: 'Maquinas', value: 'maquinas_count', align: 'center' },
        { text: 'Opções', value: '', align: 'center', sortable: false },
      ],
      excluindo: false,
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('projetos', ['projetos', 'projeto', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.projetos.busca
      },
      set (busca) {
        this.$store.commit('projetos/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.projetos.paginacao
      },
      set (paginacao) {
        this.$store.commit('projetos/UPDATE_PAGINACAO', paginacao);
      }
    },

    vTableOptions() {
      return {
        'item-key': "id",
        'mobile-breakpoint': 0,
        'server-items-length': this.total_itens,
        'footer-props': this.$v.FOOTER_PROPS,
        'headers': this.headers,
        'items': this.projetos,
      }
    }
  },


  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Projetos");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("projetos", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        // throw Error('Isso é um erro');
        await this.listar();
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível listar os projetos.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    buscar() {
      this.$store.commit('projetos/SET_PAGE', 1);
      this.refresh();
    },
    
    limparBusca() {
      this.busca = null;
      this.$store.commit('projetos/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$router.push({ name: "ProjetosAdicionar" });
    },

    async editar(projeto) {
      try {

        await this.abrir(projeto.id);
        this.$router.push({ name: "ProjetosEditar", params: { id: projeto.id } });
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir o projeto.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(projeto) {
      this.$refs.Projeto.abrir()
        .then(async response => {

          if (response == true) {
            await this.confirmarExcluir(projeto);
            this.$refs.Projeto.fechar();
          }
        })
    },

    async confirmarExcluir(projeto) {
      try {
        this.excluindo = true;
        await this.excluir(projeto.id);
        this.$eventbus.notificar("Projeto excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o projeto!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    async ordensServico(projeto) {
      await this.abrir(projeto.id);
      this.$refs.dialogoOrdensServico.abrir();
    },

    setColor(percentual) {

      if (percentual <= 25) {
        return 'red';
      }

      if (percentual > 25 && percentual <= 50) {
        return 'yellow';
      }

      if (percentual > 50 && percentual <= 75) {
        return 'blue';
      }

      if (percentual > 75) {
        return 'green';
      }
    }
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
