<template>
  <div>
    <v-container v-if="true">
        <v-row>
          <v-col cols="12" align="center">
            <v-btn color="primary" @click="alertar" class="mr-3">Alertar</v-btn>
            <v-btn color="primary" @click="outroAlerta">outroAlerta</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" align="center">
            <v-btn color="primary" @click="counterStore.increment" class="mr-3">Contar: {{ counterStore.count }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
  // import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import { mapStores } from 'pinia';

  import { useCounterStore, useGlobalStore } from "@/app/store/pinia";

  export default {
    name: 'AppTestes',

    data() {
      return {
        data: 'value'
      }
    },

    computed: {
      ...mapStores(useCounterStore, useGlobalStore),

      name() {
        return this.data;
      }
    },

    methods: {
      alertar() {
        this.$eventbus.alertar("Isso é um alerta");
      },

      outroAlerta() {
        this.$eventbus.notificar("UNAUTHORIZED");
      },
    },

    watch: {
      data(newValue, oldValue) {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>