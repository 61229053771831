<template>
  <z-dialogo
    ref="dialogo"
    titulo="Visualizar Foto"
    largura=""
    altura="600px"
    dividers
    scrollable
    fullscreen
    title-color="primary white--text"
    tile
  >

    <template v-slot:titlebuttons>
      <v-btn icon dark @click="fechar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

    <template v-slot:conteudo>

      <!-- <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Visualizar Foto</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar> -->

          <v-container>
            <v-row>
              <v-col>
                <v-img aspect-ratio="1" :src="foto.url"></v-img>
              </v-col>
            </v-row>
          </v-container>

        <!-- </v-card>
      </v-dialog> -->
    </template>
  </z-dialogo>
</template>

<script>
  export default {
    name: 'exibir-foto',

    props: {
      foto: {
        type: Object,
        default: () => {}
      },
    },

    data() {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false
      }
    },

    mounted() {
      
    },

    methods: {
      abrir() {
        this.$refs.dialogo.abrir();
        // this.dialog = true;
      },
      
      fechar() {
        this.$refs.dialogo.fechar();
        // this.dialog = false;
      }
    },
  }
</script>

<style scoped>

</style>