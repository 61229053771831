import createCrudApi from "@/app/services/baseApi";
const maquinasApi = createCrudApi('maquinas');

const axios = maquinasApi.axios;

maquinasApi.atualizarStatusComponente = function (projeto_maquina_componente) {
  return axios.put('maquina/statuscomponente', {...projeto_maquina_componente}).then((response) => response.data);
}

export default maquinasApi;
