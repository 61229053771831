import listasApi from "@/app/services/listasApi";

export default {
  namespaced: true,
  state: {
    clientes: [],
    vendedores: [],
    fornecedores: [],
    bancos: [],
    perfis: [],
  },

  getters: {
    clientes(state) {
      return state.clientes.map((cliente) => {
        return {
          text: cliente.id + " - " + cliente.nome,
          value: cliente.id,
        };
      });
    },

    getClienteById(state) {
      return (id) => {
        return state.clientes.find((cliente) => cliente.id === id);
      };
    },

    vendedores(state) {
      return state.vendedores.map((vendedor) => {
        return {
          text: vendedor.nome,
          value: vendedor.id,
        };
      });
    },
    fornecedores(state) {
      return state.fornecedores.map((fornecedor) => {
        return {
          text: fornecedor.id + " - " + fornecedor.razao,
          value: fornecedor.id,
        };
      });
    },
    bancos(state) {
      return state.bancos.map((banco) => {
        return {
          text: banco.codigo + " - " + banco.descricao,
          value: banco.codigo,
        };
      });
    },
    usuarios(state) {
      return state.usuarios.map((usuario) => {
        return {
          text: usuario.name,
          value: usuario.id,
        };
      });
    },
    perfis(state) {
      return state.perfis.map((perfil) => {
        return {
          text: perfil.descricao,
          value: perfil.id,
        };
      });
    },
  },

  mutations: {
    SET_CLIENTES(state, clientes) {
      state.clientes = clientes;
    },

    SET_VENDEDORES(state, vendedores) {
      state.vendedores = vendedores;
    },

    SET_FORNECEDORES(state, fornecedores) {
      state.fornecedores = fornecedores;
    },

    SET_BANCOS(state, bancos) {
      state.bancos = bancos;
    },
    SET_PERFIS(state, perfis) {
      state.perfis = perfis;
    },
    SET_USUARIOS(state, usuarios) {
      state.usuarios = usuarios;
    },
  },

  actions: {
    async todas({ commit, dispatch }) {
      dispatch("clientes");
      dispatch("vendedores");
      dispatch("fornecedores");
    },

    async clientes({ commit }) {
      // commit('LOADING_ON', null, {root: true});
      let clientes = await listasApi.clientes();
      commit("SET_CLIENTES", clientes);
      // commit('LOADING_OFF', null, {root: true});
    },

    async vendedores({ commit }) {
      // commit('LOADING_ON', null, {root: true});
      let vendedores = await listasApi.vendedores();
      commit("SET_VENDEDORES", vendedores);
      // commit('LOADING_OFF', null, {root: true});
    },

    async fornecedores({ commit }) {
      // commit('LOADING_ON', null, {root: true});
      let fornecedores = await listasApi.fornecedores();
      commit("SET_FORNECEDORES", fornecedores);
      // commit('LOADING_OFF', null, {root: true});
    },

    async bancos({ commit }) {
      // commit('LOADING_ON', null, {root: true});
      let bancos = await listasApi.bancos();
      commit("SET_BANCOS", bancos);
      // commit('LOADING_OFF', null, {root: true});
    },
    async usuarios({ commit }) {
      // commit('LOADING_ON', null, {root: true});
      let usuarios = await listasApi.usuarios();
      commit("SET_USUARIOS", usuarios);
      // commit('LOADING_OFF', null, {root: true});
    },
    async perfis({ commit }) {
      // commit('LOADING_ON', null, {root: true});
      let perfis = await listasApi.perfis();
      commit("SET_PERFIS", perfis);
      // commit('LOADING_OFF', null, {root: true});
    },
  },
};
