import { render, staticRenderFns } from "./zDebugInfo.vue?vue&type=template&id=f5c8036a&scoped=true&"
import script from "./zDebugInfo.vue?vue&type=script&lang=js&"
export * from "./zDebugInfo.vue?vue&type=script&lang=js&"
import style0 from "./zDebugInfo.vue?vue&type=style&index=0&id=f5c8036a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5c8036a",
  null
  
)

export default component.exports