export default {
  // id: null,
  tipo_cliente: 'F',
  nome: '',
  endereco: '',
  bairro: '',
  cidade: '',
  estado: 'PE',
  cep: '',
  ponto_referencia: '',
  telefone: '',
  telefone_2: '',
  telefone_3: '',
  fax: '',
  email: '',
  data_nascimento: '',
  estado_civil: 'Casado',
  sexo: 'M',
  rg: '',
  orgao_expedidor: '',
  cpf_cnpj: '',
  inscricao_estadual: '',
  inscricao_municipal: '',
  contato: '',
  tipo: 4,
  dia_visita: '',
  representante_id: '',
  observacoes: '',
  nome_pai: '',
  nome_mae: '',
  profissao: '',
  conjuge: '',
  local_trabalho: '',
  telefone_trabalho: '',
  funcao: '',
  referencia_pessoal: '',
  referencia_comercial: '',
  ultimo_pedido: '',
  maior_pedido: '',
  data_ultimo_pedido: '',
  data_maior_pedido: '',
  valor_maior_pedido: '',
  indicado_por: ''
}
