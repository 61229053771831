<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>{{ $icon.MAQUINAS }}</v-icon>Adicionar
        </v-btn>

        <v-btn
          text
          id="btn-atualizar"
          @click="refresh"
          :loading="$store.state.loading"
        >
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="maquinas"
            item-key="id"
          >
            <template v-slot:item="{ item: maquina }">
              <tr>
                <td>{{ maquina.id }}</td>
                <td>
                  <router-link class="text-decoration-none" :to="{ name: 'MaquinasEditar', params: { id: maquina.id } }">
                    {{ maquina.descricao }}
                  </router-link>
                </td>
                <td class="text-center">{{ maquina.maquina_componentes_count }}</td>
                <td class="text-center">{{ maquina.tempo_estimado | horaMinutos }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(maquina)">
                    <v-icon>{{ $icon.EDITAR }}</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(maquina)">
                    <v-icon>{{ $icon.EXCLUIR }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemoverMaquina"
      titulo="Remover maquina"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      O maquina será removida do sistema. Deseja remover?
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "MaquinasLista",

  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Descrição", value: "descricao" },
        { text: "Componentes", value: "componentes_count", align: "center" },
        { text: "Tempo Estimado", value: "tempo_estimado", align: "center" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      maquina_codigo: "",
    };
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Maquinas");
  },

  methods: {
    ...mapMutations(["setTitulo", "LOADING_OFF"]),
    ...mapActions("maquinas", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível listar os maquinas.", error);
      }
    },

    buscar() {
      this.$store.commit("maquinas/SET_PAGE", 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit("maquinas/SET_PAGE", 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit("maquinas/ZERAR");
      this.$router.push({ name: "MaquinasAdicionar" });
    },

    async editar(maquina) {
      try {
        await this.abrir(maquina.id);
        this.$router.push({ name: "MaquinasEditar", params: { id: maquina.id } });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a máquina.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(maquina) {
      this.$refs.dialogoRemoverMaquina.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(maquina);
          this.$refs.dialogoRemoverMaquina.fechar();
        }
      });
    },

    async confirmarExcluir(maquina) {
      try {
        this.excluindo = true;
        await this.excluir(maquina.id);
        this.$eventbus.notificar("Máquina excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o maquina!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    historico(maquina) {
      this.maquina_codigo = maquina.codigo;
      this.$refs.dialogoHistoricoMaquina.abrir();
    },
  },

  computed: {
    ...mapState(["loading"]),
    ...mapState("maquinas", ["maquinas", "maquina", "total_itens"]),

    busca: {
      get() {
        return this.$store.state.maquinas.busca;
      },
      set(busca) {
        this.$store.commit("maquinas/SET_BUSCA", busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.maquinas.paginacao;
      },
      set(paginacao) {
        this.$store.commit("maquinas/UPDATE_PAGINACAO", paginacao);
      },
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
