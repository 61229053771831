<template>
  <v-progress-circular
    :rotate="-90"
    :size="80"
    :width="12"
    :value="percentual"
    :color="setColor(percentual)"
  >
    <span style="color: black !important; font-size: 1.4em">{{ percentual }}%</span>
  </v-progress-circular>
</template>

<script>
  export default {
    name: 'z-percentual-projeto',
    props: {
      percentual: {
        type: Number,
        default: 0
      },
    },

    methods: {
      setColor(percentual) {

        if (percentual <= 25) {
          return 'red';
        }

        if (percentual > 25 && percentual <= 50) {
          return 'yellow';
        }

        if (percentual > 50 && percentual <= 75) {
          return 'blue';
        }

        if (percentual > 75) {
          return 'green';
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>