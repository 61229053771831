<template>
  <v-app>

    <v-app-bar app color="primary" dark>
      <div class="d-flex justify-content-center align-center mx-auto text-no-wrap" style="width: 1440px;">

        <v-btn icon v-if="$vuetify.breakpoint.width > 1001">
          <v-icon>mdi-tools</v-icon>
        </v-btn>

        <v-toolbar-title>
          <span class="mr-2 apontador">{{ $config.APP_NAME }}</span>
          <span class="text-caption mr-2">{{ $config.APP_VERSION }}</span>
        </v-toolbar-title>
        
        <!-- <v-divider vertical inset class="ml-4"></v-divider>
        
        <v-toolbar-title class="ml-5">
          <span class="mr-2">Andamento Projeto</span>
        </v-toolbar-title> -->
      </div>

      <v-spacer></v-spacer>

      <v-btn text @click="logout">
        <v-icon left>mdi-exit-to-app</v-icon> Sair
      </v-btn>
    </v-app-bar>   

    <v-main class="grey lighten-4">
        <z-loading :loading="loading" v-if="loading"></z-loading>
        <z-container v-else>

          <z-cabecalho>Andamento do Projeto</z-cabecalho>

          <v-card class="mb-5">
            <v-toolbar flat dense>
              <h3 class="text-subtitle-2">Projeto</h3>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    bordered
                    color="error"
                    overlap
                    offset-x="20"
                    offset-y="15"
                    dot
                    :value="projeto.arquivos_count"
                  >
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon 
                      @click="$refs.arquivos.abrir()"
                    >
                      <v-icon>{{ $icon.ANEXOS }}</v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>Arquivos</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="text--primary pa-3">
              <v-card flat class="pa-3 grey lighten-3 rounded-lg">
                <v-row>
                  <v-col cols="10">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <label for="" class="font-weight-bold">Número do Projeto</label>
                        <div>{{ projeto.id }}</div>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <label for="" class="font-weight-bold">Nome do Projeto</label>
                        <div>{{ projeto.descricao }}</div>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <label for="" class="font-weight-bold">Cliente</label>
                        <div>{{ projeto.cliente.nome }}</div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <label for="" class="font-weight-bold">Tempo Estimado</label>
                        <div>{{ projeto.tempo_estimado }}</div>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <label for="" class="font-weight-bold">Data Início</label>
                        <div>{{ projeto.data_inicio   | dataBR }}</div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label for="" class="font-weight-bold">Data Previsão</label>
                        <div>{{ projeto.data_prevista | dataBR }}</div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label for="" class="font-weight-bold">Data Finalização</label>
                        <div>{{ projeto.data_finalizacao | dataBR }}</div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label for="" class="font-weight-bold">Previsão em dias</label>
                        <div>45 dias</div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="2" class="text-center">
                    <z-percentual-projeto :percentual="projeto.percentual_concluido"></z-percentual-projeto>
                  </v-col>
                </v-row>

              </v-card>
            </v-card-text>
          </v-card>

          <z-cabecalho>Máquinas do Projeto</z-cabecalho>

          <template v-for="(maquina, index) in projeto.maquinas">
            <v-card class="mb-6" :key="index">
              <v-card-title class="text-subtitle-2 py-3">
                <b class="mr-2">MAQUINA:</b> {{ maquina.descricao }}
                <v-spacer></v-spacer>

                <v-badge
                      class="mr-3"
                      bordered
                      color="primary"
                      overlap
                      offset-xx="15"
                      offset-yy="15"
                      :content="maquina.fotos_count"
                      :value="maquina.fotos_count"
                    >
                  <v-btn icon @click="fotosMaquinas(maquina)">
                    <v-icon>mdi-camera</v-icon>
                  </v-btn>
                </v-badge>
              </v-card-title>

              <v-card-title class="py-3">
                <z-percentual-maquina width="100%" class="text-subtitle-2" :percentual="maquina.percentual_maquina"></z-percentual-maquina>
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text class="pa-0" v-if="false">
                <div class="pa-4 text--primary font-weight-bold">Componentes/Partes</div>
                <v-divider></v-divider>
                <v-data-table 
                  mobile-breakpoint="0" 
                  hide-default-footer 
                  disable-pagination 
                  disable-sort 
                  :headers="headers" 
                  :items="maquina.componentes" 
                >
                  
                  <template v-slot:item="{ item: componente, index }">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ componente.descricao }}</td>
                      <td class="text-center">
                        <v-chip v-bind="getCorChip(componente.status)" small  @click="alterarStatus(maquina, componente)">
                          {{ componente.status }}
                        </v-chip>
                      </td>
                      <td class="text-center">{{ componente.pecas_count }}</td>
                    </tr>
                  </template>
                </v-data-table>

                <v-divider></v-divider>

                <!-- <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="adicionarMaquina">Adicionar</v-btn>
                </v-card-actions> -->
              </v-card-text>
            </v-card>
          </template>

        </z-container>
    </v-main>

    <fotos-maquinas
      hide-buttons
      ref="dialogoFotosMaquinas"
      :projeto="projeto"
      :maquina="maquina"
    ></fotos-maquinas>

    <arquivos ref="arquivos" :projeto="projeto" download-only></arquivos>

  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { clone, pluck, TimeToSeconds, SecondsToTimeHMS } from '@/app/helpers/utils';
import projeto from "@/app/store/models/projeto";

import * as regras from '@/app/helpers/validacoes';

import FotosMaquinas from "../FotosMaquinas";
import Arquivos from '@/pages/arquivos/Arquivos';

import maquinasApi from "@/app/services/maquinasApi"

export default {
    name: 'ProjetosForm',

    components: {
      FotosMaquinas,
      Arquivos,
    },

    data() {
      return {
        // projeto: {...projeto},
        salvando: false,
        tab: 0  ,
        form_valido: true,
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio beatae nobis quaerat, officiis velit delectus voluptates obcaecati veniam sunt labore eligendi harum molestiae explicabo nesciunt provident. Voluptatum alias repellendus cum.',
        regras: {...regras},

        error: {
          message: "",
        },

        rota_lista: { name: 'ProjetosLista' },

        headers: [
          { text: 'Item', value: '', width: '50px' },
          { text: 'Descrição', value: 'descricao', width: '100%' },
          { text: 'Status', value: 'status', align: 'center' },
          { text: 'Peças', value: '', align: 'center' },
        ],

        componente: {},
        maquina: {},
        loading: false,
      };
    },

    computed: {
      ...mapGetters('listas', ['clientes']),
      ...mapState('projetos', ['projeto']),


      mostrarMaquinas() {
        return !this.$store.state.projetos.projeto.id ? false : true;
      },
    },

    beforeRouteLeave(to, from, next) {
      if (to.name == 'ProjetosLista') {
        this.ZERAR();
      }
      next();
    },

    created() {
      if (!this.$auth.checkOTP()) {
        this.$router.push({name: 'LoginOTP'});
      }
    },

    async mounted() {
      if (!this.$store.state.projetos.projeto.id && this.$route.params.id) {
        try {
          this.loading = true;
          await this.abrirProgresso({
            projeto_id: this.$route.params.id, 
            otp_token: this.$auth.getOTPToken()
          });
          this.loading = false;
        } catch (error) {
          this.logout();
        }
      }

        // this.projeto = clone(this.$store.state.projetos.projeto);

      // this.$store.dispatch('listas/clientes');
    },

    methods: {
      ...mapMutations(["setTitulo"]),
      ...mapMutations("projetos", ["ZERAR"]),
      ...mapActions("projetos", ["abrir", "atualizar", "salvar", "abrirProgresso"]),

      jaExiste(maquinas, codigo) {
        const index = maquinas.findIndex((maquina) => maquina.id === codigo);
        return index > -1 ? true : false;
      },

      getCorChip: function(status) {
        if (status == 'A Fazer') {
          return {
            color: 'blue-grey lighten-4',
            dark: false
          };
        }

        if (status == 'Fazendo') {
          return {
            color: 'blue',
            dark: true
          };
        }

        if (status == 'Finalizado') {
          return {
            color: 'green',
            dark: true
          };
        }

        if (status == 'Atrasado') {
          return {
            color: 'red',
            dark: true
          };
        }

        return {
          color: 'blue-grey lighten-4',
          dark: false
        };
      },

      alterarStatus(maquina, componente) {
        this.maquina = {...maquina};
        this.componente = {...componente};
        this.$refs.dialogoStatusComponente.abrir();
      },

      logout() {
        this.$auth.logoutOTP();
        this.$router.push({name: 'LoginOTP'});
      },

      fotosMaquinas(maquina) {
        this.maquina = maquina;
        setTimeout(() => {
          this.$refs.dialogoFotosMaquinas.abrir();
        }, 100);
      }
    },

    watch: {
      'projeto.cpfcnpj'(val, oldval){
        if (val) {
          this.projeto.tipo_pessoa = val.length > 14 ? 'J' : 'F';
        }
      }
    }
};
</script>

<style scoped>

</style>
