<template>
  <div>
    <v-card class="mb-3">
      <v-toolbar flat color="white" dense>
        <h3 class="text-subtitle-2">Adicionar Peca</h3>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pb-0">
        <v-row dense>
          <v-col cols="2" sm="2">
            <v-text-field
              v-model="peca.codigo"
              outlined dense
              ref="codigo"
              label="Código"
              placeholder=" "
              autofocus
              :loading="buscando"
              :hide-details="hidedetails"
              :disabled="buscando"
              @keyup.enter="buscarPecaPorCodigo"
              @keyup.esc="onKeyUpEscAdicionarPeca"
              @click:append="$refs.dialogoBuscarPecas.abrir()"
              :append-icon="$icon.BUSCA"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="6">
            <v-text-field
              v-model="peca.descricao"
              outlined dense
              ref="descricao"
              label="Descrição"
              placeholder=" "
              :hide-details="hidedetails"
              :disabled="buscando"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2">
            <z-campo-valor
              config-off
              v-model="peca.quantidade"
              outlined dense
              append-icon="mdi-keyboard-return"
              ref="quantidade"
              label="Quantidade"
              placeholder=" "
              :suffix="peca.unidade"
              :decimais="0"
              :disabled="buscando || !peca.descricao"
              @click:append="confirmarPeca"
              @keyup.enter="confirmarPeca"
              @keyup.esc="onKeyUpEscAdicionarPeca"
            ></z-campo-valor>
          </v-col>
          <!-- <v-col cols="2" sm="2">
            <z-campo-valor
              config-off
              v-model="peca.valor"
              outlined dense
              append-icon="mdi-keyboard-return"
              ref="valor"
              label="Valor"
              placeholder=" "
              :loading="adicionando"
              :hide-details="hidedetails"
              :disabled="buscando || !peca.descricao || adicionando"
              @click:append="confirmarPeca"
              @keyup.enter="confirmarPeca"
              @keyup.esc="onKeyUpEscAdicionarPeca"
            ></z-campo-valor>
          </v-col> -->
          <!-- <v-col cols="2" sm="2">
            <z-campo-valor
              config-off
              v-model="peca.subtotal"
              outlined dense
              ref="subtotal"
              label="SubTotal"
              placeholder=" "
              :hide-details="hidedetails"
              readonly
              :disabled="buscando || !peca.descricao"
            ></z-campo-valor>
          </v-col> -->

          <v-col cols="12" sm="2" v-if="peca.descricao">
            <v-btn icon color="primary" @click="confirmarPeca">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="limparPeca">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialogo-buscar-pecas ref="dialogoBuscarPecas" @busca:selecionar="selecionarPeca"></dialogo-buscar-pecas>

  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

  import { trunc } from "@/app/helpers/conversoes";

  const peca = {
    peca_id: '',
    codigo: '',
    descricao: '',
    valor: 0,
    quantidade: 1,
    unidade: 'UN',
    subtotal: 0,
  }

  export default {
    name: 'AdicionarPecaForm',

    props: {
      componente: {
        type: Object,
        default: () => {}
      },
    },

    data() {
      return {
        peca: {...peca},

        buscando: false,
        hidedetails: false,
        salvando: false,
        adicionando: false,
      }
    },

    computed: {
      name() {
        return this.data;
      }
    },

    methods: {
      adicionarPeca() {
        this.$refs.dialogoBuscarPecas.abrir();
      },

      selecionarPeca(peca) {
        console.log({...peca});

        this.peca.peca_id = peca.id;
        this.peca.codigo = peca.codigo;
        this.peca.descricao = peca.descricao;
        this.peca.unidade = peca.unidade;
        this.peca.quantidade = 1;
        this.peca.valor = peca.valor_venda;
        this.peca.subtotal =  Number(this.peca.quantidade) *  Number(this.peca.valor);
        this.setFocus("quantidade", 300);

        // if (this.jaExiste(this.componente.pecas, peca.codigo)) {
        //   this.$eventbus.alertar('Esta peça já foi adicionada a esse componente.');
        //   return false;
        // }

        // this.componente.pecas.push(peca);
      },

      jaExiste(pecas, codigo) {
        const index = pecas.findIndex((peca) => peca.codigo === codigo);
        return index > -1 ? true : false;
      },

      limparPeca() {
        this.peca = {...peca}
      },

      confirmarPeca() {
        if (this.jaExiste(this.componente.pecas, this.peca.codigo)) {
          this.$eventbus.alertar('Esta peça já foi adicionada a esse componente.');
          this.setFocus("codigo", 300);
          return false;
        }

        this.componente.pecas.push(this.peca);
        this.limparPeca();
        this.setFocus("codigo", 300);
      },

      onKeyUpEscAdicionarPeca() {
        this.limparPeca();
        this.setFocus("codigo");
      },

      async buscarPecaPorCodigo() {
        if (!this.peca.codigo) {
          this.$refs.dialogoBuscarPecas.abrir();

          return false;
        }

        // if (this.jaExiste(this.componente.pecas, this.peca.codigo)) {
        //   this.$eventbus.alertar('Esta peça já foi adicionada a esse componente.');
        //   return false;
        // }

        try {
          this.buscando = true;
          let peca = await this.$store.dispatch("pecas/buscarPecaPorCodigo", this.peca.codigo);

          if (peca) {
            this.selecionarPeca(peca);
          }
        } catch (error) {
          this.$eventbus.notificarErro("Peça não encontrada.", error);
          this.setFocus("codigo", 300);
          this.selectText("codigo");
        } finally {
          this.buscando = false;
        }
      },

      calcularSubTotal() {
        this.peca.subtotal = trunc(
          Number(this.peca.quantidade) * Number(this.peca.valor)
        );
      },
    },

    watch: {
      peca: {
        immediate: true,
        deep: true,
        handler(peca, oldValue) {
          if (peca.descricao) {
            this.calcularSubTotal();
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>