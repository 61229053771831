import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function clientes() {
  return axios.get("listas/clientes").then((response) => response.data);
}

function vendedores() {
  return axios.get("listas/vendedores").then((response) => response.data);
}

function fornecedores() {
  return axios.get("listas/fornecedores").then((response) => response.data);
}

function bancos() {
  return axios.get("listas/bancos").then((response) => response.data);
}

function usuarios() {
  return axios.get("listas/usuarios").then((response) => response.data);
}

function perfis() {
  return axios.get("listas/perfis").then((response) => response.data);
}

function funcionarios() {
  return axios.get("/listas/funcionarios").then((response) => response.data);
}

function prazos() {
  return axios.get("/listas/prazos").then((response) => response.data);
}

function grupos() {
  return axios.get("/listas/grupos").then((response) => response.data);
}

function subgrupos() {
  return axios.get("/listas/subgrupos").then((response) => response.data);
}

function tipos_funcionarios() {
  return axios.get("/listas/tipos_funcionarios").then((response) => response.data);
}

function tipos_os() {
  return axios.get("/listas/tipos_os").then((response) => response.data);
}

function tipos_clientes() {
  return axios.get("/listas/tipos_clientes").then((response) => response.data);
}

function tipos_movimento() {
  return axios.get("/listas/tiposmovimento").then((response) => response.data);
}

export default {
  clientes,
  vendedores,
  fornecedores,
  bancos,
  usuarios,
  perfis,
  funcionarios,
  prazos,
  grupos,
  subgrupos,
  tipos_funcionarios,
  tipos_os,
  tipos_clientes,
  tipos_movimento,
};
