import Vue from 'vue'
import Router from 'vue-router'

import Home from "@/pages/Home";
import Inicio from "@/pages/Inicio";
import TemplatesRoutes from './routes/templates';

import UsuariosRoutes from './routes/usuarios';
import PublicoRoutes from './routes/publico';
import ClientesRoutes from './routes/clientes';
import ProjetosRoutes from './routes/projetos';
import MaquinasRoutes from './routes/maquinas';
import ComponentesRoutes from './routes/componentes';
import PecasRoutes from './routes/pecas';
import EstoqueRoutes from './routes/estoque';
import AndamentoRoutes from './routes/andamento';
import SistemaRoutes from './routes/sistema';


import { guards } from './guards.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },

  routes: [
    { path: "/", name: "Home", component: Home },

    ...TemplatesRoutes,

    ...UsuariosRoutes,
    ...PublicoRoutes,

    ...ClientesRoutes,
    ...ProjetosRoutes,
    ...MaquinasRoutes,
    ...ComponentesRoutes,
    ...PecasRoutes,
    ...EstoqueRoutes,
    ...AndamentoRoutes,
    ...SistemaRoutes,
  ]
})

router.beforeEach(guards);

export default router;