<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-tools</v-icon>Adicionar
        </v-btn>

        <v-btn
          text
          id="btn-atualizar"
          @click="refresh"
          :loading="$store.state.loading"
        >
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="pecas"
            item-key="id"
          >
            <template v-slot:item="{ item: peca }">
              <tr>
                <td>{{ peca.codigo }}</td>
                <td>
                  <router-link class="text-decoration-none" :to="{ name: 'EstoqueEditar', params: { id: peca.id } }">
                    {{ peca.descricao }}
                  </router-link>
                </td>
                <td class="text-right">{{ peca.estoque }}</td>
                <td class="text-right">{{ peca.unidade }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(peca)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(peca)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemoverPeca"
      titulo="Remover peça"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      A peça será removida do sistema. Deseja remover?
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "EstoqueLista",

  data() {
    return {
      headers: [
        { text: "Código", value: "codigo", width: "150px" },
        { text: "Descrição", value: "descricao" },
        { text: "Estoque", value: "estoque", align: "right" },
        { text: "Unidade", value: "unidade", align: "right" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      peca_codigo: "",
    };
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Movimento Estoque");
  },

  methods: {
    ...mapMutations(["setTitulo", "LOADING_OFF"]),
    ...mapActions("pecas", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar os peças.");
      }
    },

    buscar() {
      this.$store.commit("pecas/SET_PAGE", 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit("pecas/SET_PAGE", 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit("pecas/ZERAR");
      this.$router.push({ name: "EstoqueAdicionar" });
    },

    async editar(peca) {
      try {
        await this.abrir(peca.id);
        this.$router.push({ name: "EstoqueEditar", params: { id: peca.id } });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível abrir a peça.");
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(peca) {
      this.$refs.dialogoRemoverPeca.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(peca);
          this.$refs.dialogoRemoverPeca.fechar();
        }
      });
    },

    async confirmarExcluir(peca) {
      try {
        this.excluindo = true;
        await this.excluir(peca.id);
        this.$eventbus.notificar("Peça excluída com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o peça!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    historico(peca) {
      this.peca_codigo = peca.codigo;
      this.$refs.dialogoHistoricoPeca.abrir();
    },
  },

  computed: {
    ...mapState(["loading"]),
    ...mapState("pecas", ["pecas", "peca", "total_itens"]),

    busca: {
      get() {
        return this.$store.state.pecas.busca;
      },
      set(busca) {
        this.$store.commit("pecas/SET_BUSCA", busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.pecas.paginacao;
      },
      set(paginacao) {
        this.$store.commit("pecas/UPDATE_PAGINACAO", paginacao);
      },
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
