import componentesApi from "@/app/services/componentesApi";
import componente from "@/app/store/models/componente";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    componente: {...componente},
    componentes: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, componentes) {
      state.componentes = componentes;
    },

    ABRIR(state, componente) {
      state.componente = componente;
    },

    ADICIONAR(state, componente) {
      state.componentes.unshift(componente);
    },

    ATUALIZAR(state, componente) {
      let index = state.componentes.findIndex(componente_ => componente_.id == componente.id);
      if (index > -1) {
        state.componentes.splice(index, 1, componente);
      }
    },

    EXCLUIR(state, id) {
      let index = state.componentes.findIndex(componente => componente.id == id);
      if (index > -1) {
        state.componentes.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.componente = {...componente};
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await componentesApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let componente = await componentesApi.abrir(id);
      commit('ABRIR', componente);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, componente) {
      let novocomponente = await componentesApi.salvar(componente);
      commit('ADICIONAR', novocomponente);
      commit('ABRIR', novocomponente);
    },

    async atualizar({ commit }, componente) {
      await componentesApi.atualizar(componente);
      commit('ATUALIZAR', componente);
    },

    async excluir({ commit }, id) {
      await componentesApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
