import EstoqueLista from '@/pages/movimentacoes/estoque/EstoqueLista'
import EstoqueForm from '@/pages/movimentacoes/estoque/EstoqueForm'

import permissoes from "@/pages/sistema/usuarios/permissoes";

export default [
  {
    path: '/movimentacoes/estoque', 
    name: 'EstoqueLista', 
    component: EstoqueLista,
    meta: {layout: 'principal', permissao: permissoes.estoque.ACCESAR.id}
  },
  
  {
    path: '/movimentacoes/estoque/adicionar', 
    name: 'EstoqueAdicionar', 
    component: EstoqueForm,
    meta: {layout: 'principal', permissao: permissoes.estoque.ADICIONAR.id}
  },
  
  {
    path: '/movimentacoes/estoque/:id/editar', 
    name: 'EstoqueEditar', 
    component: EstoqueForm,
    meta: { layout: 'principal', permissao: permissoes.estoque.EDITAR.id}
  }
];
