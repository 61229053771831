<template>
  <div>
    <z-dialogo ref="dialogo" titulo="Arquivos" dividers largura="1000px">

      <template v-slot:titlebuttons>
        <v-btn
          v-if="projeto.id"
          color="primary"
          small
          v-show="!downloadOnly"
          @click="$refs.fileUpload.abrir()"
        >Adicionar Arquivo</v-btn>
      </template>

      <template v-slot:conteudo>
        <z-loading :loading="carregando">

          <v-data-table
            mobile-breakpoint="0"
            disable-pagination
            disable-sort
            hide-default-footer
            :headers="headers"
            :items="arquivos"
            :loading="carregando"
            fixed-header_
            height="383px"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(arquivo, index) in items">
                  <td>{{ arquivo.descricao }}</td>
                  <td>{{ arquivo.nome }}</td>
                  <td class="text-center">
                    <v-btn icon :href="$config.BASE_URL + '/arquivos/'+arquivo.id+'/download'">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn icon @click="perguntarExcluir(arquivo)" v-show="!downloadOnly">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>

          </v-data-table>

        </z-loading>
      </template>
      
      <template v-slot:actions_right>
        <v-btn color="primary" @click="fechar()">Fechar</v-btn>
      </template>

    </z-dialogo>

    <file-upload ref="fileUpload" :projeto="projeto" @onConcluir="onConcluirUpload"></file-upload>

    <z-dialogo-sim-nao
      ref="dialogoRemover"
      titulo="Remover arquivo"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      O arquivo será removido. Deseja remover?
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

  import FileUpload from './FileUpload'

  export default {
    name: 'Arquivos',

    props: {
      projeto: {
        type: Object,
        default: () => {}
      },
      downloadOnly: {
        type: Boolean,
        default: false
      }
    },

    components: {
      FileUpload
    },

    data() {
      return {
        arquivos: [],
        carregando: false,
        excluindo: false,
         headers: [
          { text: "Descrição", value: "descricao" },
          { text: "Nome do Arquivo", value: "nome" },
          {
            text: "Opções",
            value: "",
            width: "200px",
            align: "center",
            sortable: false,
          },
        ],
      }
    },

    computed: {
      name() {
        return this.data;
      }
    },

    methods: {
      abrir() {
        this.$refs.dialogo.abrir();
        this.carregarArquivos();
      },

      fechar() {
        this.$refs.dialogo.fechar();
        this.$emit('fechar')
      },

      async carregarArquivos() {
        this.arquivos = await this.$axios.get("arquivos/" + this.projeto.id).then(response => response.data);
      },

      perguntarExcluir(arquivo) {
        this.$refs.dialogoRemover.abrir().then(async response => {
          if (response == true) {
            await this.$axios.delete(`arquivos/${arquivo.id}`).then(response => response.data);
            this.carregarArquivos();
            this.$refs.dialogoRemover.fechar();
          }
        });
      },

      onConcluirUpload(arquivo) {
        this.carregarArquivos();
        // arquivo.processado = false;
        // arquivo.processando = false;

        // setTimeout(() => {
        //   this.enviados.unshift(arquivo);
        // }, 500);

      },
    },

    watch: {
      data(newValue, oldValue) {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>