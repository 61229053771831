import LoginOTP from '@/pages/movimentacoes/cliente/LoginOTP'
import ProgressoProjetos from '@/pages/movimentacoes/cliente/ProgressoProjetos'

export default [

  {
    path: '/cliente/login', 
    name: 'LoginOTP', 
    component: LoginOTP,
    meta: { layout: 'full', public: true, permissao: 'clientes_login_otp'}
  },

  {
    path: '/cliente/login/:chave', 
    name: 'LoginOTP', 
    component: LoginOTP,
    meta: { layout: 'full', public: true, permissao: 'clientes_login_otp'}
  },
  
  {
    path: '/cliente/projeto/:id/progresso', 
    name: 'ProgressoProjeto', 
    component: ProgressoProjetos,
    meta: { 
      layout: 'full', 
      public: true, 
      permissao: 'clientes_progresso_projetos',
      type: 'otp'
    }
  }

];