<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{name: 'ProjetosLista'}" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn text @click="$router.push({ name: 'ProjetosLista'})" :disabled="salvando">Cancelar</v-btn>
      <v-btn class="ml-3" color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      

        <z-container>

          <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

          <v-form ref="formulario" v-model="form_valido" lazy-validation>
            <v-card class="mb-5">
              <v-toolbar flat color="white" dense>
                <h3 class="text-subtitle-2">Dados do Projeto</h3>
                <v-spacer></v-spacer>
                <h3 class="text-subtitle-2">Chave Progresso: <b class="text-subtitle-1 font-weight-bold">{{ projeto.chave_otp }}</b></h3>

                <v-btn id="btnGerarChave" icon :loading="chave_otp_loading" @click="gerarNovaChaveOTP(projeto.id)">
                  <v-icon>mdi-refresh</v-icon>
                  <v-tooltip bottom activator="#btnGerarChave">
                    Gerar nova chave
                  </v-tooltip>
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>

                <v-row dense>
                  <v-col cols="12" sm="2">
                    <z-text-field v-model="projeto.id" label="Número Projeto" readonly></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <z-text-field label="Nome Projeto" v-model="projeto.descricao" :rules="[regras.campoObrigatorio]"></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <z-select label="Cliente" :items="clientes" v-model="projeto.cliente_id" :rules="[regras.campoObrigatorio]"></z-select>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <z-time-picker 
                      label="Tempo estimado" 
                      v-model="projeto.tempo_estimado"
                      hint="Tempo em horas"
                      persistent-hint
                      readonly
                    ></z-time-picker>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-date-picker-menu v-model="projeto.data_inicio" label="Data Início"></z-date-picker-menu>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-date-picker-menu v-model="projeto.data_finalizacao" label="Data Finalização"></z-date-picker-menu>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-date-picker-menu v-model="projeto.data_prevista" label="Data Previsão"></z-date-picker-menu>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-form>

          <v-card class="mb-6" v-if="mostrarMaquinas">
            <v-card-title class="text-subtitle-2">
              Máquinas do Projeto
              <v-spacer></v-spacer>
              <v-btn class="ma-0" rounded small color="primary" @click="adicionarMaquina">
                  <v-icon left>mdi-plus</v-icon>
                Adicionar Máquina
              </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <v-data-table 
                mobile-breakpoint="0" 
                hide-default-footer 
                disable-pagination 
                disable-sort 
                :headers="headers" 
                :items="projeto.maquinas" 
                fixed-header 
              >
                
                <template v-slot:item="{ item: maquina, index }">
                  <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ maquina.maquina_id }}</td>
                    <td>
                      <router-link 
                        class="text-decoration-none" 
                        :to="{ name: 'MaquinasEditar', params: { id: maquina.maquina_id } }"
                        target="_blank"
                      >
                        {{ maquina.descricao }}
                      </router-link>
                      <!-- {{ maquina.descricao || '' }} -->

                    </td>
                    <td class="text-center">{{ maquina.tempo_estimado | horaMinutos }}</td>
                    <td class="text-center">
                      <v-btn icon @click="perguntarExcluir(index)" class="ma-0">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:body.append>
                  <tr>
                    <td colspan="3"></td>
                    <td class="text-center"><h3>{{ tempoTotal }}</h3></td>
                    <td></td>
                  </tr>
                </template>

              </v-data-table>
            </v-card-text>
          </v-card>

        </z-container>
    </z-scroll-layout>

    <dialogo-buscar-maquinas ref="dialogoBuscarMaquinas" @busca:selecionar="selecionarMaquina"></dialogo-buscar-maquinas>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { clone, pluck, TimeToSeconds, SecondsToTimeHMS } from '@/app/helpers/utils';
import projeto from "@/app/store/models/projeto";

import * as regras from '@/app/helpers/validacoes';

export default {
    name: 'ProjetosForm',

    data() {
      return {
        // projeto: {...projeto},
        salvando: false,
        tab: 0  ,
        form_valido: true,
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio beatae nobis quaerat, officiis velit delectus voluptates obcaecati veniam sunt labore eligendi harum molestiae explicabo nesciunt provident. Voluptatum alias repellendus cum.',
        regras: {...regras},

        error: {
          message: "",
        },

        rota_lista: { name: 'ProjetosLista' },

        headers: [
          { text: 'Item', value: '', width: '50px' },
          { text: 'Código', value: 'id', width: '100px' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Tempo Estimado', value: 'tempo_estimado', align: 'center' },
          // { text: 'Quantidade', value: 'quantidade', align: 'right' },
          { text: 'Opções', value: '', width: '200px', align: 'center' },
        ],
        chave_otp_loading: false,
      };
    },

    computed: {
      ...mapGetters('listas', ['clientes']),
      ...mapState('projetos', ['projeto']),


      titulo() {
        let titulo = (/Editar/).test(this.$route.name) ? 'Editar' : 'Adicionar';
        return titulo + ' ' + 'Projeto';
      },

      mostrarMaquinas() {
        return !this.$store.state.projetos.projeto.id ? false : true;
      },

      tempoTotal() {
        if (!this.projeto.maquinas.length) {
          return '00:00';
        }
        
        let segundos = pluck(this.projeto.maquinas, 'tempo_estimado')
          .map(item => {
            return TimeToSeconds(item);
          }).reduce((item, soma) => {
            return soma + item;
          },0);

        return SecondsToTimeHMS(segundos).substring(0,5);
      }
    },

    beforeRouteLeave(to, from, next) {
      if (to.name == 'ProjetosLista') {
        this.ZERAR();
      }
      next();
    },

    async mounted() {
      this.setTitulo(this.titulo);

      if (this.$route.name == "ProjetosEditar") {
        // if (!this.$store.state.projetos.projeto.id) {
          if (this.$route.params.id) {
            await this.abrir(this.$route.params.id);
          }
        // }

        // this.projeto = clone(this.$store.state.projetos.projeto);

      }

      this.$store.dispatch('listas/clientes');
    },

    methods: {
      ...mapMutations(["setTitulo"]),
      ...mapMutations("projetos", ["ZERAR"]),
      ...mapActions("projetos", ["abrir", "atualizar", "salvar", "gerarChaveOTP"]),

      async confirmar() {
        this.error.message = "";

        if (!this.$refs.formulario.validate()) {
          return false;
        }

        try {
          this.salvando = true;

          if (this.$route.params.id) {
            // let projeto = this.transformeProjeto({...this.projeto});

            await this.atualizar(this.projeto);
            // this.$router.push(this.rota_lista);

          } else {
            await this.salvar(this.projeto);
              this.$router.push({ 
                name: "ProjetosEditar", 
                params: {
                  id: this.$store.state.projetos.projeto.id
                } 
              });
          }

          this.$eventbus.notificar("Projeto salvo com sucesso.");
        } catch(error) {
          if (error.status == 422) {
            this.error.message = error.data.errors.codigo[0];
            return;
          }

          this.$eventbus.notificarErro('Não foi possível salvar este Projeto. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      adicionarMaquina() {
        // this.zerarPeca();
        // this.$refs.formulario.resetValidation();
        this.$refs.dialogoBuscarMaquinas.abrir();
      },

      selecionarMaquina(maquina) {
        if (this.jaExiste(this.projeto.maquinas, maquina.id)) {
          this.$eventbus.alertar('Este maquina já foi adicionada a esse projeto.');
          return false;
        }

        this.projeto.maquinas.push({
          projeto_id: this.projeto.id,
          maquina_id: maquina.id,
          descricao: maquina.descricao,
          tempo_estimado: maquina.tempo_estimado
        });

        this.projeto.tempo_estimado = this.tempoTotal;

      },

      async perguntarExcluir(index) {
        let resposta = await this.$root.dialogo_pergunta.abrir({
          titulo: 'Remover maquina?',
          texto: 'O maquina será removido da máquina.',
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
        });

        if (resposta == 'Remover') {
          this.projeto.maquinas.splice(index, 1);
          this.projeto.tempo_estimado = this.tempoTotal;
        }
        
        this.$root.dialogo_pergunta.fechar();
      },

      transformeProjeto(projeto) {
        let maquinas = pluck(projeto.maquinas, 'id');
        projeto.codigo_maquinas = maquinas;
        delete projeto.maquinas;
        return projeto;
      },

      jaExiste(maquinas, codigo) {
        const index = maquinas.findIndex((maquina) => maquina.id === codigo);
        return index > -1 ? true : false;
      },

      async gerarNovaChaveOTP(projeto_id) {

        try {
          this.chave_otp_loading = true;
          await this.gerarChaveOTP(projeto_id);
        } catch (error) {
          this.$eventbus.notificarErro("Erro ao gerar nova chave de acesso ao projeto");
        } finally {
          this.chave_otp_loading = false;
        }
      }
    },

    watch: {
      'projeto.cpfcnpj'(val, oldval){
        if (val) {
          this.projeto.tipo_pessoa = val.length > 14 ? 'J' : 'F';
        }
      }
    }
};
</script>

<style scoped>

</style>
