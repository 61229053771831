<template>
  <z-dialogo
    ref="dialogo"
    :titulo="`Fotos Máquina - ${maquina.descricao}`"
    largura=""
    altura="600px"
    dividers
    scrollable
    eager
    fullscreen
  >

    <template v-slot:conteudo>

      <z-loading :loading="loading">
        <v-row>
          <v-col
            v-for="(foto, index) in fotos"
            class="d-flex child-flex"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            :key="index"
          >
            <v-card @click="exibirFoto(foto)" style="cursor: pointer">
              <v-img
                aspect-ratio="1"
                class="grey lighten-2"
                :src="foto.url"
                :lazy-src="foto.url"
              >
                <!-- <template v-slot:placeholder>
                  <v-row fill-height align-center justify-center px-4>
                    <v-progress-linear 
                      v-if="foto.progresso > 0 && foto.progresso < 100"
                      height="5" 
                      :value="foto.progresso"
                      color="indigo">
                    </v-progress-linear>
                    <v-progress-circular v-else indeterminate color="indigo"></v-progress-circular>
                  </v-row>
                </template> -->
              </v-img>

              <v-card-actions style="position: relative;" v-show="!hideButtons">
                <v-spacer></v-spacer>
                <v-btn icon :loading="foto.excluindo" @click.stop="perguntarExcluir(foto)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-col>
        </v-row>
      </z-loading>

    </template>

    <template v-slot:actions_left v-if="$auth.check()">
      <input hidden @change="onFileChange" ref="captura" type="file" accept="image/*">
      <v-btn color="primary" depressed @click="$refs.captura.click()" :loading="salvando" v-show="!hideButtons">
        <v-icon left>mdi-camera</v-icon>
        Adicionar Foto
      </v-btn>
    </template>

    <template v-slot:actions_right>
      <v-btn text color="primary" @click="fechar" :disabled="salvando">Fechar</v-btn>
    </template>

    <exibir-foto ref="exibirfoto" :foto="foto"></exibir-foto>

    <z-dialogo-sim-nao ref="dialogoRemoverFoto" titulo="Remover foto?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O foto será removida do sistema.
    </z-dialogo-sim-nao>

  </z-dialogo>
</template>

<script>

  import fotosApi from '@/app/services/fotosApi'
  import ExibirFoto from "./ExibirFoto";

  export default {
    name: 'FotosMaquinas',

    components: {
      ExibirFoto,
    },

    props: {
      projeto: {
        type: Object,
        default: () => {}
      },

      maquina: {
        type: Object,
        default: () => {}
      },

      hideButtons: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        loading: false,
        salvando: false,
        form_valido: false,
        excluindo: false,

        foto: {},
        fotos: [

        ]
      }
    },

    methods: {
      async abrir() {
        try {
          this.loading = true;
          this.$refs.dialogo.abrir();
          this.fotos = await this.carregarFotos(this.projeto.id, this.maquina.id);
        } catch (error) {
          this.$eventbus.notificarErro('Houve um erro.', error);
        } finally {
          this.loading = false;
        }

      },

      fechar() {
        this.$refs.dialogo.fechar();
      },

      salvar() {
        
      },

      async carregarFotos(projeto_id, maquina_id){
        let fotos = await fotosApi.listar(projeto_id, maquina_id);
        return fotos;
      },

      async onFileChange() {
        if (this.$refs.captura.files[0]) {
          const arquivo = this.$refs.captura.files[0];
          this.fotoUpload(arquivo);
        }
        // this.drawOnCanvas(arquivo);   // see Example 6
        // this.displayAsImage(arquivo); // see Example 7
      },

      adicionarUmaFoto(arquivo) {
        console.log(arquivo);
        let reader = new FileReader();

        reader.onload = async (event) => {
          this.fotos.push({
            projeto_id: this.projeto.id,
            maquina_id: this.maquina.id,
            arquivo: '',
            progresso: 0
          })
          // let indice = this.fotos.length-1;
          let foto = await this.fotoUpload(arquivo);
          // this.$set(this.fotos, indice, foto);
        }

        reader.readAsDataURL(arquivo);
      },

      async salvarFoto() {
        try {
          this.salvando = true;
          await this.fotoUpload();
        } catch (error) {
          console.log(error);
        } finally {
          this.salvando = false;
        }
      },

      async fotoUpload(foto) {

        const form_data = new FormData();
        form_data.append('projeto_id', this.projeto.id);
        form_data.append('maquina_id', this.maquina.id);
        form_data.append('foto', foto);

        try {
          this.salvando = true;

          // let foto = this.fotos[this.fotos.length - 1];
          await fotosApi.upload(form_data, {
            // onUploadProgress: uploadEvent => {
            //   foto.progresso = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            // },
            headers: {
              'content-type': 'multipart/form-data'
            }
          });

          this.fotos = await this.carregarFotos(this.projeto.id, this.maquina.id);
          setTimeout(() => {
            this.$refs.dialogo.scrollEnd();
          }, 500);
        } catch (error) {
          console.log(error);
        } finally {
          this.salvando = false;
        }
      },

      async removerFoto(foto) {
        this.$set(foto, 'excluindo', true);

        try {
          await fotosApi.remover(foto.id);
          let index = this.fotos.findIndex(f => f.id == foto.id);
          this.fotos.splice(index, 1);
        } catch (error) {
          foto.excluindo = false;
          console.log(error.response.data);
        }
      },

      exibirFoto(foto) {
        this.foto = foto;
        this.$refs.exibirfoto.abrir();
      },

      // fotoURL(foto) {
      //   if (foto.arquivo && foto.arquivo.length == 36) {
      //     return foto.url;
      //   } else {
      //     return foto.arquivo;
      //   }
      // },

      perguntarExcluir(foto) {
        this.$refs.dialogoRemoverFoto.abrir()
          .then(async response => {

            if (response == true) {
              await this.removerFoto(foto);
              this.$refs.dialogoRemoverFoto.fechar();
            }
          })
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>