import ClientesLista from '@/pages/cadastros/clientes/ClientesLista'
import ClientesForm from '@/pages/cadastros/clientes/ClientesForm'

export default [
  {
    path: '/cadastros/clientes', 
    name: 'ClientesLista', 
    component: ClientesLista,
    meta: {layout: 'principal', permissao: 'clientes_acessar'}
  },
  
  {
    path: '/cadastros/clientes/adicionar', 
    name: 'ClientesAdicionar', 
    component: ClientesForm,
    meta: {layout: 'principal', permissao: 'clientes_adicionar'}
  },
  
  {
    path: '/cadastros/clientes/:id/editar', 
    name: 'ClientesEditar', 
    component: ClientesForm,
    meta: { layout: 'principal', permissao: 'clientes_editar'}
  }
];
