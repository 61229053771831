// import clientesApi from "@/app/services/clientesApi";
import cliente from "@/app/store/models/cliente";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";
import { hoje } from "@/app/helpers/utils";

import createCrudApi from "@/app/services/baseApi";
const clientesApi = createCrudApi('clientes');

export default {
  namespaced: true,
  state: {
    cliente: {...cliente},
    clientes: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, clientes) {
      state.clientes = clientes;
    },

    ABRIR(state, cliente) {
      state.cliente = cliente;
    },

    ADICIONAR(state, cliente) {
      state.clientes.push(cliente);
    },

    ATUALIZAR(state, cliente) {
      let index = state.clientes.findIndex(cliente_ => cliente_.id == cliente.id);
      if (index > -1) {
        state.clientes.splice(index, 1, cliente);
      }
    },

    EXCLUIR(state, id) {
      let index = state.clientes.findIndex(cliente => cliente.id == id);
      if (index > -1) {
        state.clientes.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.cliente = {...cliente};
      state.cliente.data_cadastro = hoje();
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await clientesApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let cliente = await clientesApi.abrir(id);
      commit('ABRIR', cliente);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, cliente) {
      let novocliente = await clientesApi.salvar(cliente);
      commit('ADICIONAR', novocliente);
    },

    async atualizar({ commit }, cliente) {
      await clientesApi.atualizar(cliente);
      commit('ATUALIZAR', cliente);
    },

    async excluir({ commit }, id) {
      await clientesApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
