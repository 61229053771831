import createCrudApi from "@/app/services/baseApi";
const HorasTrabalhadasApi = createCrudApi('horastrabalhadas');

const axios = HorasTrabalhadasApi.axios;

HorasTrabalhadasApi.listar = function (params) {
  return axios.get('horastrabalhadas', { params: params }).then((response) => response.data);
}

export default HorasTrabalhadasApi;
