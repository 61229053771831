import createCrudApi from "@/app/services/baseApi";
const projetosApi = createCrudApi('projetos');


projetosApi.abrirAndamento = id => {
  return projetosApi.axios.get(`/projetos/${id}/andamento`).then((response) => response.data);
}

projetosApi.abrirProgresso = (id, otp_token) => {
  return projetosApi.axios.get(`/projetos/${id}/progresso`, {params: { otp_token: otp_token }}).then((response) => response.data);
}

projetosApi.gerarChaveOTP = id => {
  return projetosApi.axios.put(`/projetos/${id}/chaveotp`).then((response) => response.data);
}

export default projetosApi;
