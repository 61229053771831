<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="rota_lista" exact>
          <v-icon left>{{ $icon.VOLTAR }}</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        text
        :disabled="salvando"
        @click="$router.push(rota_lista)"
        >Cancelar</v-btn
      >
      <v-btn
        class="ml-3"
        color="primary"
        :loading="salvando"
        :disabled="!form_valido"
        @click="confirmar"
        >Salvar</v-btn
      >
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
          >{{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">Dados da Máquina </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field label="Código" v-model="maquina.id" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <z-text-field
                    upper
                    label="Descrição"
                    v-model="maquina.descricao"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-time-picker 
                    label="Tempo estimado" 
                    v-model="maquina.tempo_estimado"
                    hint="Tempo em horas"
                    persistent-hint
                    readonly
                  ></z-time-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

        <v-card class="mb-6" v-if="mostrarPecas">
          <v-card-title class="text-subtitle-2">
            Componentes da Máquina
            <v-spacer></v-spacer>
            <v-btn class="ma-0" rounded small color="primary" @click="adicionarComponente">
                <v-icon left>mdi-plus</v-icon>
              Adicionar Componente
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="pa-0">
            <v-data-table 
              mobile-breakpoint="0" 
              hide-default-footer 
              disable-pagination 
              disable-sort 
              :headers="headers" 
              :items="maquina.maquina_componentes" 
            >
              
              <template v-slot:item="{ item: maquina_componentes, index }">
                <tr>
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ maquina_componentes.componente_id }}</td>
                  <td>
                    <router-link 
                      class="text-decoration-none" 
                      :to="{ name: 'ComponentesEditar', params: { id: maquina_componentes.componente_id } }"
                      target="_blank"
                    >
                      {{ maquina_componentes.descricao }}
                    </router-link>
                  </td>
                  <td class="text-center">{{ maquina_componentes.pecas_count }}</td>
                  <td class="text-center">{{ maquina_componentes.tempo_estimado | horaMinutos }}</td>
                  <td class="text-center">
                    <v-btn icon @click="perguntarExcluir(index)" class="ma-0">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:body.append>
                <tr>
                  <td colspan="4"></td>
                  <td class="text-center"><h3>{{ tempoTotal }}</h3></td>
                  <td></td>
                </tr>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>

      </z-container>
    </z-scroll-layout>

    <dialogo-buscar-componentes ref="dialogoBuscarComponentes" @busca:selecionar="selecionarComponente"></dialogo-buscar-componentes>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { clone, pluck, TimeToSeconds, SecondsToTimeHMS } from "@/app/helpers/utils";
import maquina from "@/app/store/models/maquina";

import * as regras from "@/app/helpers/validacoes";

export default {
  name: "MaquinasForm",

  data() {
    return {
      // maquina: {...maquina},
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      error: {
        message: "",
      },

      rota_lista: { name: 'MaquinasLista' },

      headers: [
        { text: 'Item', value: '', width: '50px' },
        { text: 'Código', value: 'id', width: '100px' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Peças', value: 'pecas_count', align: 'center' },
        { text: 'Tempo Estimado', value: 'tempo_estimado', align: 'center' },
        { text: 'Opções', value: '', width: '200px', align: 'center' },
      ],
    };
  },

  computed: {
    // ...mapGetters("listas", ["grupos", "subgrupos"]),
    ...mapState('maquinas', ['maquina']),

    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Máquina";
    },

    mostrarPecas() {
      return !this.$store.state.maquinas.maquina.id ? false : true;
    },

    tempoTotal() {

      let segundos = this.maquina.maquina_componentes.map(maquina_componente => {
        return TimeToSeconds(maquina_componente.tempo_estimado);
      }).reduce((item, soma) => {
        return soma + item;
      }, 0);

      return SecondsToTimeHMS(segundos).substring(0,5);
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == 'MaquinasLista') {
      this.ZERAR();
    }
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "MaquinasEditar") {
      if (!this.$store.state.maquinas.maquina.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
        
      // this.maquina = clone(this.$store.state.maquinas.maquina);
    }
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("maquinas", ["ZERAR"]),
    ...mapActions("maquinas", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          // let maquina = this.transformeMaquina({...this.maquina});

          await this.atualizar(this.maquina);
          // this.$router.push(this.rota_lista);

        } else {
          await this.salvar(this.maquina);

          this.$router.push({ 
            name: "MaquinasEditar", 
            params: {
              id: this.$store.state.maquinas.maquina.id
            } 
          });
        }

        this.$eventbus.notificar("Máquina salva com sucesso");
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.codigo[0];
          return;
        }

        this.$eventbus.notificarErro("Não foi possível salvar este máquina. Verifique os dados preenchidos.", error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    adicionarComponente() {
      // this.zerarPeca();
      // this.$refs.formulario.resetValidation();
      this.$refs.dialogoBuscarComponentes.abrir();
    },

    selecionarComponente(componente) {
      if (this.jaExiste(this.maquina.maquina_componentes, componente.id)) {
        this.$eventbus.alertar('Este componente já foi adicionado a essa máquina.');
        return false;
      }


      this.maquina.maquina_componentes.push({
        maquina_id: this.maquina.id,
        componente_id: componente.id,
        descricao: componente.descricao,
        tempo_estimado: componente.tempo_estimado,
        pecas_count: componente.pecas_count,
      });
      this.maquina.tempo_estimado = this.tempoTotal;
    },

    async perguntarExcluir(index) {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Remover componente?',
        texto: 'O componente será removido da máquina.',
        botoes: ['Cancelar', 'Remover'],
        padrao: 'Remover',
        cor: 'red',
      });

      if (resposta == 'Remover') {
        this.maquina.maquina_componentes.splice(index, 1);
      }
      
      this.$root.dialogo_pergunta.fechar();
    },

    transformeMaquina(maquina) {
      let componentes = pluck(maquina.componentes, 'id');
      maquina.codigo_componentes = componentes;
      delete maquina.componentes;
      return maquina;
    },

    jaExiste(componentes, id) {
      const index = componentes.findIndex(componente => componente.id === id);
      return index > -1 ? true : false;
    }
  },
};
</script>

<style scoped>
</style>
