<script setup>

import { ref, computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: 'Filtro:'
  },

  items: {
    type: Array,
    default: () => []
  },

  value: {
    type: String,
  }
});

const emit = defineEmits(['input']);

const button_text = computed(() => {
  const item = props.items.find(item => item.value == props.value)
  return !!item ? item.text : props.items[0].text;
})

function change(item) {
  emit('input', item.value);
}

</script>

<template>
  <span>
    <v-menu bottom origin="center center">
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined rounded v-bind="attrs" v-on="on">
          {{ label }} <span class="text--secondary">{{ button_text }}</span>
          <v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
  
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" @click="change(item)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action>
            <v-icon v-if="item.value == value">mdi-check</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>


<style lang="scss" scoped>

</style>
