import maquinasApi from "@/app/services/maquinasApi";
import maquina from "@/app/store/models/maquina";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    maquina: {...maquina},
    maquinas: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, maquinas) {
      state.maquinas = maquinas;
    },

    ABRIR(state, maquina) {
      state.maquina = maquina;
    },

    ADICIONAR(state, maquina) {
      state.maquinas.push(maquina);
      state.maquina = maquina;
    },

    ATUALIZAR(state, maquina) {
      let index = state.maquinas.findIndex(maquina_ => maquina_.id == maquina.id);
      if (index > -1) {
        state.maquinas.splice(index, 1, maquina);
      }
    },

    EXCLUIR(state, id) {
      let index = state.maquinas.findIndex(maquina => maquina.id == id);
      if (index > -1) {
        state.maquinas.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.maquina = {...maquina};
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await maquinasApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let maquina = await maquinasApi.abrir(id);
      commit('ABRIR', maquina);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, maquina) {
      let novomaquina = await maquinasApi.salvar(maquina);
      commit('ADICIONAR', novomaquina);
    },

    async atualizar({ commit }, maquina) {
      await maquinasApi.atualizar(maquina);
      commit('ATUALIZAR', maquina);
    },

    async excluir({ commit }, id) {
      await maquinasApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
