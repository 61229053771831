<script setup>

  const emit = defineEmits(['input']);
  
  const props = defineProps({
    value: {
      type: [String, Number],
      default: "",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    itens: {
      type: Array,
      default: () => [],
    },

    small: {
      type: Boolean,
      default: false,
    },
  });

  const onChange = function(value) {
    emit('input', value)
  }

</script>

<template>
  <v-btn-toggle :value="value" v-bind="$attrs" v-on="$listeners" @change="onChange">
    <v-btn color="primary" :value="item.value" text :small="small" v-for="(item, index) in itens" :key="index" :disabled="loading">
      {{ item.descricao }}
    </v-btn>
  </v-btn-toggle>
</template>

<style lang="scss" scoped>

</style>