<template>
  <v-slide-y-transition mode="out-in">
    <template v-if="$route.meta.layout == 'full'">
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <layout-horizontal-novo v-else></layout-horizontal-novo>


  </v-slide-y-transition>
</template>

<script>
import LayoutVertical from "@/pages/layout/AppLayoutVertical.vue";
import LayoutHorizontal from "@/pages/layout/AppLayoutHorizontal.vue";
import LayoutHorizontalNovo from "@/pages/layout/AppLayoutHorizontalNovo.vue";

export default {
  name: "App",
  components: {
    LayoutVertical,
    LayoutHorizontal,
    LayoutHorizontalNovo
  },

  data() {
    return {};
  },
};
</script>

<style>

</style>
