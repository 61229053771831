import * as icons from "../../../app/icons";

export default {
  clientes: {
    ACCESAR: { id: "clientes_acessar", name: "Acessar", icon: icons.ACESSAR, },
    ADICIONAR: { id: "clientes_adicionar", name: "Adicionar", icon: icons.ADICIONAR, },
    EDITAR: {  id: "clientes_editar",  name: "Editar",  icon: icons.EDITAR  },
    EXCLUIR: {  id: "clientes_excluir",  name: "Excluir",  icon: icons.EXCLUIR  },
    IMPRIMIR: { id: "clientes_imprimir", name: "Imprimir", icon: icons.IMPRIMIR, },
  },
  estoque: {
    ACCESAR: { id: "movimento_estoque_acessar", name: "Acessar", icon: icons.ACESSAR, },
    ADICIONAR: { id: "movimento_estoque_adicionar", name: "Adicionar", icon: icons.ADICIONAR, },
    EDITAR: {  id: "movimento_estoque_editar",  name: "Editar",  icon: icons.EDITAR  },
    EXCLUIR: {  id: "movimento_estoque_excluir",  name: "Excluir",  icon: icons.EXCLUIR  },
    IMPRIMIR: { id: "movimento_estoque_imprimir", name: "Imprimir", icon: icons.IMPRIMIR, },
  },
  andamento: {
    ACCESAR: { id: "andamento_projeto_acessar", name: "Acessar", icon: icons.ACESSAR, },
    ADICIONAR: { id: "andamento_projeto_adicionar", name: "Adicionar", icon: icons.ADICIONAR, },
    EDITAR: { id: "andamento_projeto_editar", name: "Editar", icon: icons.EDITAR, },
    EXCLUIR: { id: "andamento_projeto_excluir", name: "Excluir", icon: icons.EXCLUIR, },
  },
};
