<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'PecasLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        text
        :disabled="salvando"
        @click="$router.push({ name: 'PecasLista' })"
        >Cancelar</v-btn
      >
      <v-btn
        class="ml-3"
        color="primary"
        :loading="salvando"
        :disabled="!form_valido"
        @click="confirmar"
        >Salvar</v-btn
      >
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
          >{{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">Dados do Peça </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field
                    label="Código"
                    v-model="peca.codigo"
                    :disabled="peca.id ? true : false"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
                <v-col cols="12" sm="9">
                  <z-text-field
                    upper
                    label="Descrição"
                    v-model="peca.descricao"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.valor_custo"
                    label="Valor Compra"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.valor_venda"
                    label="Valor Venda"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.estoque"
                    label="Estoque"
                    decimais="0"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-select
                    v-model="peca.unidade"
                    label="Unidade"
                    :items="$globals.UNIDADES"
                  ></z-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { clone } from "@/app/helpers/utils";
import peca from "@/app/store/models/peca";

import * as regras from "@/app/helpers/validacoes";

export default {
  name: "TemplatesForm",

  data() {
    return {
      peca: {...peca},
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      error: {
        message: "",
      },
    };
  },

  computed: {
    // ...mapGetters("listas", ["grupos", "subgrupos"]),

    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Peça";
    },
  },

  beforeRouteLeave(to, from, next) {
    this.ZERAR();
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "PecasEditar") {
      if (!this.$store.state.pecas.peca.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
        
      this.peca = clone(this.$store.state.pecas.peca);
    }
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("pecas", ["ZERAR"]),
    ...mapActions("pecas", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.peca);
        } else {
          await this.salvar(this.peca);
        }

        this.$router.push({ name: "PecasLista" });
        this.$eventbus.notificar("Peça salva com sucesso");
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.codigo[0];
          return;
        }

        this.$eventbus.notificarErro("Não foi possível salvar esta peça. Verifique os dados preenchidos.", error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },
  },
};
</script>

<style scoped>
</style>
