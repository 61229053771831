<template>
  <v-app>
    <v-main :class="$vuetify.breakpoint.mdAndUp ? 'grey lighten-3' : 'white'"">
        <v-container fill-height>
          <v-row class="fill-height_" justify="center" align_="center">
            <v-col cols="12" sm="6" md="6" lg="4" xl="3">
              <v-slide-y-transition>
                <v-card :flat="$vuetify.breakpoint.smAndDown">
                  <v-container class="pa-6">
                    <v-form v-model="form_valido" ref="form">

                      <v-row class="mb-5">
                        <v-col class="text-center" cols="12" sm="12" md="12">
                          <h1 class="headline">Acesso ao Sistema</h1>
                        </v-col>
                      </v-row>

                      <v-row class="mb-8">
                        <v-col class="text-center" cols="12" sm="12" md="12">
                          <v-avatar color="primary" size="120">
                            <v-icon dark size="70">mdi-lock</v-icon>
                          </v-avatar>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-otp-input
                            ref="otp_form"
                            v-model="otp.value"
                            :length="otp.length"
                            :disabled="otp.loading"
                            :type="otp.type"
                            autocomplete="off"
                            @finish="onFinish"
                            :error="mostrar_erro"
                          ></v-otp-input>
                        </v-col>
                      </v-row>

                      <v-row justify="center" dense>
                        <v-col align="center">
                          <v-btn 
                            :disabled="!isActive"
                            min-width="200px" 
                            rounded 
                            color="primary" 
                            @click="login"
                          >Entrar</v-btn>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <RouterLink class="text-decoration-none text-primary text-body-2 font-weight-medium" :to="{ name: 'Login'}">Efetuar login</RouterLink>
                        </v-col>
                      </v-row>

                      <v-row justify="center" dense>
                        <v-col cols="12" class="text-center text-caption pb-0">
                          {{ this.$config.APP_VERSION }}
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card>
              </v-slide-y-transition>
            </v-col>
          </v-row>
        </v-container>
      <!-- <v-img height="100vh" min-height="100vh" lazy-src="/img/img_login.jpg" src="/img/img_login.jpg" gradient="to top right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)">
      </v-img> -->
    </v-main>

    <z-notificacao ref="notificacao"></z-notificacao>

    <z-aguardar ref="aguardar" color="primary"></z-aguardar>
  </v-app>
</template>

<script>

import events from "@/app/mixins/events";
import { RouterLink } from "vue-router";

export default {
  name: 'LoginOTP',
  data() {
    return {
      form_valido: false,
      otp: {
        value: '',
        length: 4,
        type: 'password', // text, password, number
        loading: false
      },
      mostrar_erro: false,
    };
  },

  computed: {
    isActive () {
      return this.otp.value.length === this.otp.length
    },
  },

  mounted() {
    events.start(this);
    // this.$store.dispatch("carregarConfiguracoes");

    const chave = this.$route.params.chave;
    if (chave) {
      this.otp.value = chave;
      this.login();
    }
  },

  beforeDestroy() {
    events.stop();
  },

  methods: {
    async login() {
      this.mostrar_erro = false;
      this.$refs.aguardar.abrir();
      try {
        const projeto = await this.$auth.loginOTP(this.otp.value);
        this.$router.push({ name: "ProgressoProjeto", params: {id: projeto.id} });
      } catch (error) {
        this.mostrar_erro = true;
        this.$eventbus.notificarErro("Chave de acesso inválida.");
        this.$refs.otp_form.focus();
      } finally {
        this.$refs.aguardar.fechar();
      }
    },

    onFinish() {
      this.login();
    }
  },

  watch: {
    "otp.value"(newValue, oldValue) {
      this.mostrar_erro = false;
    }
  },
};
</script>

<style lang="scss">

</style>
