<script setup>

  import { reactive, watchEffect } from "vue";

  const props = defineProps({
    percentual: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: '150px'
    }
  })

  const style = reactive({
    color: 'red',
    textColor: 'black--text',
  })

  const setColor = (percentual) => {
    if (percentual <= 25) {
      style.color = 'red';
      style.textColor = 'black--text';
    }

    if (percentual > 25 && percentual <= 50) {
      style.color = 'yellow';
      style.textColor = 'black--text';
    }

    if (percentual > 50 && percentual <= 75) {
      style.color = 'blue';
      style.textColor = 'white--text';
    }

    if (percentual > 75) {
      style.color = 'green';
      style.textColor = 'white--text';
    }
  }

  watchEffect(() => {
    setColor(props.percentual);
  })
</script>

<template>
  <div :style="{width: width}">
    <v-progress-linear
      height="20"
      rounded
      :value="percentual"
      :color="style.color"
    >
      <strong :class="style.textColor">{{ percentual }}%</strong>
    </v-progress-linear>
  </div>
</template>

<style lang="scss" scoped>

</style>