import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/app/router'
import createStore from '@/app/store'
import { createPinia, PiniaVuePlugin } from 'pinia';

import '@/app/bootstrap'
import vuetify from '@/app/plugins/vuetify';

Vue.config.productionTip = false

const store = createStore();
const pinia = createPinia();
Vue.use(PiniaVuePlugin);

import eventbus from "@/app/eventbus";
Vue.config.errorHandler = function (error, vm, info) {

  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+

  if (process.env.NODE_ENV !== "production") {
    eventbus.notificarErro("ATENÇÃO! HOUVE UM ERRO!", error);
  }
  
  throw error;
}

new Vue({
  router,
  store,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
