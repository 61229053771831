<template>
  <div>
    <z-container class="mt-10">
      <v-row justify="center">
        <v-col cols="12" align="center" class="mb-4">
          <v-avatar color="primary" size="150">
            <v-icon size="100" dark>$vuetify.icons.PECAS</v-icon>
          </v-avatar>
        </v-col>

        <v-col cols="12" class="mb-4" align="center">
          <h1 class="display-2 mb-3">
            <div>Bem-vindo!</div>
            <!-- <div class="font-weight-bold my-4">Panorama Centro Automotivo</div> -->
          </h1>
          <p class="overline" style="font-size: 16px !important">
            Controle de Fabricação de Máquinas.
          </p>
        </v-col>
      </v-row>

      <app-testes v-if="!$config.IS_PRODUCTION"></app-testes>

      <!-- <andamento-lista></andamento-lista> -->

      <!-- <odontograma></odontograma> -->
    </z-container>
  </div>
</template>

<script>
// import Odontograma from './Odontograma'

import { mapMutations, mapActions, mapState } from "vuex";

import * as icons from "../app/icons";

import AndamentoLista from "./movimentacoes/andamento/AndamentoLista.vue";
import AppTestes from "./layout/AppTestes"


export default {

  components: {
    AndamentoLista,
    AppTestes,
    // Odontograma
  },

  data: () => ({
    boxed: true
  }),

  computed: {
    ...mapState('projetos', ['projetos']),

  },

  created() {
    this.refresh();
  },

  async mounted() {},

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("projetos", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        // throw Error('Isso é um erro');
        await this.listar();
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível listar os projetos.", error);
      } finally {
        this.LOADING_OFF();
      }
    },
    
    async visualizar(projeto) {
      try {

        await this.abrir(projeto.id);
        this.$router.push({ name: "ProjetosEditar", params: { id: projeto.id } });
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir o projeto.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    setColor(percentual) {

      if (percentual <= 25) {
        return 'red';
      }

      if (percentual > 25 && percentual <= 50) {
        return 'yellow';
      }

      if (percentual > 50 && percentual <= 75) {
        return 'blue';
      }

      if (percentual > 75) {
        return 'green';
      }
    }

  },
};
</script>

<style lang="scss" scoped>

.borda {
    border: 1px solid red;
}

.z_container {
    height: 200px;
    padding: 0 1rem!important;
}

.app-toolbar__content {
    // flex: 1 1 auto;
    max-width: 100%;
    padding: 0 3rem!important;
}

.boxed {
    max-width: 1264px;
    padding: 0 3rem!important;
}

.sublinhado {
  text-decoration: underline;
}

.v-progress-circular__info {
  color: black !important;
}
</style>
