import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function listar(projeto_id, maquina_id) {
  return axios.get("fotos", {
    params: {
      projeto_id: projeto_id,
      maquina_id: maquina_id
    }
  }).then((response) => response.data);
}

function upload(upload) {
  return axios.post("fotos/upload", upload).then((response) => response.data);
}

function remover(foto_id) {
  return axios.delete(`fotos/${foto_id}`).then((response) => response.data);
}

function exibir() {
  return axios.get("fotos/exibir").then((response) => response.data);
}

export default {
  axios,
  listar,
  upload,
  remover,
  exibir
};
