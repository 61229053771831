import projetosApi from "@/app/services/projetosApi";
import projeto from "@/app/store/models/projeto";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";
import { hoje } from "@/app/helpers/utils";

export default {
  namespaced: true,
  state: {
    projeto: {...projeto},
    projetos: [],
    busca: "",
    total_itens: 0,
    paginacao: {...PAGINATION_OPTIONS}
  },

  mutations: {
    LISTAR(state, projetos) {
      state.projetos = projetos;
    },

    ABRIR(state, projeto) {
      state.projeto = projeto;
    },
    
    ADICIONAR(state, projeto) {
      state.projetos.push(projeto);
      state.projeto = projeto;
    },

    ATUALIZAR(state, projeto) {
      let index = state.projetos.findIndex(projeto_ => projeto_.id == projeto.id);
      if (index > -1) {
        state.projetos.splice(index, 1, projeto);
      }
    },

    EXCLUIR(state, id) {
      let index = state.projetos.findIndex(projeto => projeto.id == id);
      if (index > -1) {
        state.projetos.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.projeto = {...projeto};
      state.projeto.data_cadastro = hoje();
    },

    SET_CHAVEOTP(state, chave_otp) {
      state.projeto.userotp = {
        otp: chave_otp
      }
    },

    SET_ITEMSPERPAGE(state, itemsPerPage) {
      state.paginacao.itemsPerPage = itemsPerPage;
    },
  },

  actions: {
    async listar({ commit, state }, filtro) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0],
        status: filtro
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await projetosApi.listar(paginacao);

      if (itemsPerPage > 0) {
        commit('LISTAR', response.data);
        commit('SET_TOTAL_ITENS', response.total);
      } else {
        commit('LISTAR', response);
        commit('SET_TOTAL_ITENS', response.length);
        commit('UPDATE_PAGINACAO', {...PAGINATION_OPTIONS});
      }
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let projeto = await projetosApi.abrir(id);
      commit('ABRIR', projeto);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrirAndamento({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let projeto = await projetosApi.abrirAndamento(id);
      commit('ABRIR', projeto);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrirProgresso({ commit }, options) {
      commit('LOADING_ON', null, { root: true });
      let projeto = await projetosApi.abrirProgresso(options.projeto_id, options.otp_token);
      commit('ABRIR', projeto);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, projeto) {
      let novoprojeto = await projetosApi.salvar(projeto);
      commit('ADICIONAR', novoprojeto);
    },

    async atualizar({ commit }, projeto) {
      await projetosApi.atualizar(projeto);
      commit('ATUALIZAR', projeto);
    },

    async excluir({ commit }, id) {
      await projetosApi.excluir(id);
      commit('EXCLUIR', id);
    },

    async gerarChaveOTP({ commit }, projeto_id) {
      const chave_otp = await projetosApi.gerarChaveOTP(projeto_id);
      commit('SET_CHAVEOTP', chave_otp);
    },

  }
}
