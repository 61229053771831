import TemplatesLista from '@/pages/templatesvue/TemplatesLista'
import TemplatesForm from '@/pages/templatesvue/TemplatesForm'

export default [
  {
    path: '/templates',
    name: 'TemplatesLista',
    component: TemplatesLista,
  },
  {
    path: '/templates/adicionar',
    name: 'TemplatesAdicionar',
    component: TemplatesForm,
  },
  {
    path: '/templates/:id/editar',
    name: 'TemplatesEditar',
    component: TemplatesForm,
  },
];
